import { defineMessages } from "react-intl";

export default defineMessages({
  add_product: {
    id: "add_product",
    defaultMessage: "Crear un producto",
    description: "On catalogue page, to add a product individually",
  },
  add_service: {
    id: "add_service",
    defaultMessage: "Crear un servicio",
    description: "On catalogue page, to add a service individually", // Not in use yet
  },
  add: {
    id: "add",
    defaultMessage: "Añadir",
    description:
      "When creating an order, the button to add an article to the cart",
  },
  address_placeholder: {
    id: "address_placeholder",
    defaultMessage: " ",
    description: "When creating an order, the data form",
  },
  address: {
    id: "address",
    defaultMessage: "Dirección",
    description: "When creating an order, the data form",
  },
  amount_placeholder: {
    id: "amount_placeholder",
    defaultMessage: " ",
    description:
      "For workshops type, when creating an order, the form. Also when creating an item, the data form",
  },
  amount: {
    id: "amount",
    defaultMessage: "Importe",
    description: "Also when creating an item, the data form. Total price",
  },
  amount_error: {
    id: "amount_error",
    defaultMessage: "El importe no es válido",
    description:
      "For workshops type, an error when creating an order at the form.",
  },
  api_solicitation_creation_cancelled: {
    id: "api_solicitation_creation_cancelled",
    defaultMessage: "La solicitud no se ha creado correctamente",
    description: "",
  },
  api_solicitation_creation_error: {
    id: "api_solicitation_creation_error",
    defaultMessage: "La solicitud no se ha creado correctamente",
    description: "",
  },
  apply: {
    id: "apply",
    defaultMessage: "Aplicar",
    description: "Apply a change on the UI",
  },
  available_stores: {
    id: "available_stores",
    defaultMessage: "Tiendas disponibles",
    description: "In the sidemenu dropdown, to change the store",
  },
  back_home_2: {
    id: "back_home_2",
    defaultMessage: "Volver al inicio",
    description:
      "Catalogue page and settings page. Text-link to come back in mobile view",
  },
  back_home: {
    id: "back_home",
    defaultMessage: "Volver a la página principal",
    description:
      "Error page (404) and order status page. Default text-link to come back",
  },
  back: {
    id: "back",
    defaultMessage: "Atrás",
    description: "General text-link to go back",
  },
  cancel_order: {
    id: "cancel_order",
    defaultMessage: "Devolución del total",
    description: "Detail view of an order, to return the money",
  },
  cancelled_status_msg1: {
    id: "cancelled_status_msg1",
    defaultMessage: "La venta ha sido reembolsada",
    description: "Status page, headline",
  },
  cancelled_status_msg2: {
    id: "cancelled_status_msg2",
    defaultMessage: "Se ha procedido con la devolución del importe total.",
    description: "Detail view of an order",
  },
  cart_item: {
    id: "cart_item",
    defaultMessage: "Producto",
    description: "",
  },
  cart_items: {
    id: "cart_items",
    defaultMessage: "Productos",
    description: "",
  },
  cart_items_placeholder: {
    id: "cart_items_placeholder",
    defaultMessage: "Escribe el nombre o referencia",
    description: "Old flow to create an order",
  },
  cart_services: {
    id: "cart_services",
    defaultMessage: "Servicios",
    description: "",
  },
  catalogue_empty_help_add1: {
    id: "catalogue_empty_help_add1",
    defaultMessage: "Crea productos de forma individual",
    description: "",
  },
  catalogue_empty_help_add2: {
    id: "catalogue_empty_help_add2",
    defaultMessage: "Tamaño máximo de la imagen: {maxSize} MB.",
    description: "",
  },
  catalogue_empty_help_heading: {
    id: "catalogue_empty_help_heading",
    defaultMessage: "Empieza subiendo tus primeros artículos",
    description: "",
  },
  catalogue_empty_help_upload1: {
    id: "catalogue_empty_help_upload1",
    defaultMessage: "Sube listados completos de artículos",
    description: "",
  },
  catalogue_empty_help_upload2: {
    id: "catalogue_empty_help_upload2",
    defaultMessage:
      "Formato CSV. Tamaño máximo: {maxSize} MB. Imprescindible que el fichero cuente con las columnas: nombre, referencia y precio.",
    description: "",
  },
  category: {
    id: "category",
    defaultMessage: "Categoría",
    description: "When creating an item, the data form",
  },
  city_placeholder: {
    id: "city_placeholder",
    defaultMessage: " ",
    description: "When creating an order, the data form",
  },
  city: {
    id: "city",
    defaultMessage: "Población",
    description: "When creating an order, the data form",
  },
  concept: {
    id: "concept",
    defaultMessage: "Concepto",
    description: "A concept or name of a specific payment",
  },
  confirm_cancel_order: {
    id: "confirm_cancel_order",
    defaultMessage:
      "¿Seguro que quieres cancelar esta venta? Se procederá con la devolución del importe.",
    description: "",
  },
  confirmed_status_msg1: {
    id: "confirmed_status_msg1",
    defaultMessage: "La venta ha sido aprobada",
    description: "",
  },
  confirmed_status_msg2: {
    id: "confirmed_status_msg2",
    defaultMessage:
      "Puedes entregar la compra y recuerda que puedes ver todos las órdenes desde el listado.",
    description: "",
  },
  contact_sequra: {
    id: "contact_sequra",
    defaultMessage: "Contactar con seQura",
    description: "",
  },
  continue: {
    id: "continue",
    defaultMessage: "Continuar",
    description: "",
  },
  cost: {
    id: "cost",
    defaultMessage: "Coste",
    description: "",
  },
  create_order_cart_amount_below_min: {
    id: "create_order_cart_amount_below_min",
    defaultMessage: "El valor debe ser superior a {min} €",
    description: "",
  },
  create_order_only_one_service_allowed: {
    id: "create_order_only_one_service_allowed",
    defaultMessage: "Sólo es posible añadir un servicio",
    description: "",
  },
  create_order_start_searching_services: {
    id: "create_order_start_searching_services",
    defaultMessage:
      "Empieza a añadir servicios buscando por nombre o referencia",
    description: "",
  },
  create_order_start_searching: {
    id: "create_order_start_searching",
    defaultMessage:
      "Empieza a añadir productos buscando por nombre o referencia",
    description: "",
  },
  create_order_step_data: {
    id: "create_order_step_data",
    defaultMessage: "Completar datos",
    description: "When creating an order, the stepper",
  },
  create_order_step_items: {
    id: "create_order_step_items",
    defaultMessage: "Añadir productos",
    description: "When creating an order, the stepper",
  },
  create_order_step_services: {
    id: "create_order_step_services",
    defaultMessage: "Añadir servicios",
    description: "When creating an order, the stepper",
  },
  created_at: {
    id: "created_at",
    defaultMessage: "Fecha de creación",
    description: "",
  },
  customer: {
    id: "customer",
    defaultMessage: "Cliente",
    description: "Detail view of an order, the info",
  },
  date_of_birth_placeholder: {
    id: "date_of_birth_placeholder",
    defaultMessage: "20/10/2000",
    description: "",
  },
  date_of_birth: {
    id: "date_of_birth",
    defaultMessage: "Fecha de nacimiento",
    description: "When creating an order, the data form",
  },
  delete_values: {
    id: "delete_values",
    defaultMessage: "Borrar valores",
    description: "Reset inputs fields to default values",
  },
  description: {
    id: "description",
    defaultMessage: "Descripción",
    description: "When creating an item, the data form",
  },
  detail: {
    id: "detail",
    defaultMessage: "Detalle",
    description: "",
  },
  disclaimer_for_svea: {
    id: "disclaimer_for_svea",
    defaultMessage:
      "* Servicio financiero proporcionado por {logo} actuando SeQura como proveedor del servicio tecnológico",
    description: "SVEA disclaimer",
  },
  discount: {
    id: "discount",
    defaultMessage: "Descuento",
    description: "Discount label",
  },
  discount_apply_btn: {
    id: "discount_apply_btn",
    defaultMessage: "Aplicar descuento",
    description: "",
  },
  discount_before_label: {
    id: "discount_before_label",
    defaultMessage: "Antes: ",
    description: "",
  },
  discount_euro: {
    id: "discount_euro",
    defaultMessage: "Descuento (€)",
    description: "",
  },
  discount_final_price: {
    id: "discount_final_price",
    defaultMessage: "Precio final",
    description: "",
  },
  discount_original_price: {
    id: "discount_original_price",
    defaultMessage: "Precio original",
    description: "",
  },
  discount_total_quantity_label: {
    id: "discount_total_quantity_label",
    defaultMessage: "Total: ",
    description: "",
  },
  discount_total_quantity: {
    id: "discount_total_quantity",
    defaultMessage:
      "{quantity} {quantity, plural, one {artículo} other {artículos}}",
    description: "",
  },
  edit_catalogue_item_success: {
    id: "edit_catalogue_item_success",
    defaultMessage: "El artículo se ha actualizado correctamente",
    description: "",
  },
  edit_catalogue_item: {
    id: "edit_catalogue_item",
    defaultMessage: "Editar artículo",
    description: "",
  },
  edit: {
    id: "edit",
    defaultMessage: "Editar",
    description: "",
  },
  electronic: {
    id: "electronic",
    defaultMessage: "Electricidad y electrónica",
    description: "For workshops type, when creating an order, the form",
  },
  email: {
    id: "email",
    defaultMessage: "Email",
    description: "",
  },
  email_taken: {
    id: "email_taken",
    defaultMessage: "El email ya está en uso",
    description: "",
  },
  empty_catalogue_items: {
    id: "empty_catalogue_items",
    defaultMessage: "No hay artículos disponibles en el catálogo",
    description: "",
  },
  empty_orders: {
    id: "empty_orders",
    defaultMessage: "Aún no se han creado ventas",
    description: "",
  },
  error_status_msg1: {
    id: "error_status_msg1",
    defaultMessage: "Ups! Algo ha ido mal",
    description: "",
  },
  error_status_msg2: {
    id: "error_status_msg2",
    defaultMessage:
      "Se ha producido un error, contáctanos lo antes posible. Disculpa las molestias.",
    description: "",
  },
  error_validation_email: {
    id: "error_validation_email",
    defaultMessage: "Las credenciales no son válidas",
    description: "",
  },
  euro_instalment: {
    id: "euro_instalment",
    defaultMessage: "€/plazo",
    description: "",
  },
  euro_month: {
    id: "euro_month",
    defaultMessage: "€/mes",
    description: "",
  },
  exceed_max_filesize: {
    id: "exceed_max_filesize",
    defaultMessage: "El tamaño del fichero debe ser inferior a {maxSize} MB",
    description: "",
  },
  first_installment: {
    id: "first_installment",
    defaultMessage: "Primer pago hoy",
    description: "",
  },
  free: {
    id: "free",
    defaultMessage: "gratis",
    description: "",
  },
  generic_search_placeholder: {
    id: "generic_search_placeholder",
    defaultMessage: "Escribe para buscar...",
    description: "",
  },
  given_names_placeholder: {
    id: "given_names_placeholder",
    defaultMessage: " ",
    description: "When creating an order, the data form",
  },
  given_names: {
    id: "given_names",
    defaultMessage: "Nombre",
    description: "When creating an order, the data form",
  },
  green_card: {
    id: "green_card",
    defaultMessage: "Tarjeta verde",
    description: "",
  },
  handling: {
    id: "handling",
    defaultMessage: "Envío",
    description: "Title for a handling or shipping service",
  },
  handling_modify: {
    id: "handling_modify",
    defaultMessage: "Modificar envío",
    description: "Title button to modify shipping concept and cost",
  },
  help_link_help: {
    id: "help_link_help",
    defaultMessage: "Ayuda",
    description: "Sidemenu item",
  },
  help_link_manual: {
    id: "help_link_manual",
    defaultMessage: "Documentación", // Manual de uso
    description: "Sidemenu item",
  },
  homepage_credit_agreement_input_placeholder: {
    id: "homepage_credit_agreement_input_placeholder",
    defaultMessage: "Importe de la venta",
    description: "",
  },
  homepage_credit_agreement_input_title: {
    id: "homepage_credit_agreement_input_title",
    defaultMessage: "Simulador de plan de pago",
    description: "",
  },
  homepage_widget_waiting_description: {
    id: "homepage_widget_waiting_description",
    defaultMessage: "Estamos cargando la información del simulador.",
    description: "Homepage loading page, text",
  },
  homepage_widget_waiting_title: {
    id: "homepage_widget_waiting_title",
    defaultMessage: "Esperando un momento",
    description: "Homepage loading page, heading",
  },
  image: {
    id: "image",
    defaultMessage: "Imagen",
    description: "",
  },
  imagePicker_addButtonText: {
    id: "imagePicker_addButtonText",
    defaultMessage: "Subir una imagen",
    description: "When creating an item, the button to upload",
  },
  imagePicker_helpText1: {
    id: "imagePicker_helpText1",
    defaultMessage: "Formatos aceptados: JPG y PNG.",
    description: "When creating an item, the data form",
  },
  imagePicker_helpText2: {
    id: "imagePicker_helpText2",
    defaultMessage: "Tamaño máximo: {maxSize} MB.",
    description: "When creating an item, the data form",
  },
  imagePicker_helpTitle: {
    id: "imagePicker_helpTitle",
    defaultMessage: "Arrastrar una imagen",
    description: "When creating an item, the data form",
  },
  imagePicker_removeButtonText: {
    id: "imagePicker_removeButtonText",
    defaultMessage: "Eliminar",
    description: "When creating an item, the button to delete",
  },
  imagePicker_updateButtonText: {
    id: "imagePicker_updateButtonText",
    defaultMessage: "Cambiar imagen",
    description: "When creating an item, the button to change",
  },
  in_progress_status_msg1: {
    id: "in_progress_status_msg1",
    defaultMessage: "En progreso...",
    description: "",
  },
  in_progress_status_msg2: {
    id: "in_progress_status_msg2",
    defaultMessage:
      "El cliente ya ha empezado el proceso. Puedes ayudarlo o continuar atendiendo.",
    description: "",
  },
  inactive_status_msg1: {
    id: "inactive_status_msg1",
    defaultMessage: "La venta está inactiva",
    description: "",
  },
  inactive_status_msg2: {
    id: "inactive_status_msg2",
    defaultMessage:
      "El cliente no ha completado la compra, pero puede retomar el proceso desde el link del SMS.",
    description: "",
  },
  insert_email: {
    id: "insert_email",
    defaultMessage: "Correo electrónico",
    description: "Login page",
  },
  insert_password: {
    id: "insert_password",
    defaultMessage: "Contraseña",
    description: "Login page",
  },
  insert_pin: {
    id: "insert_pin",
    defaultMessage: "Código PIN",
    description: "Login page, for workshop type",
  },
  installment: {
    id: "installment",
    defaultMessage: "Cuota",
  },
  item_create_error: {
    id: "item_create_error",
    defaultMessage: "La referencia ya está en uso",
    description: "",
  },
  link_faqs: {
    id: "link_faqs",
    defaultMessage: "https://www.sequra.com/preguntas-frecuentes",
    description: "",
  },
  link_shopper_app: {
    id: "link_shopper_app",
    defaultMessage: "https://app.sequra.com",
    description: "",
  },
  list_order_ref_mobile: {
    id: "list_order_ref_mobile",
    defaultMessage: "Ref: {reference}",
    description: "",
  },
  list_order_tel_mobile: {
    id: "list_order_tel_mobile",
    defaultMessage: "Tel: {mobile_phone}",
    description: "",
  },
  machinery: {
    id: "machinery",
    defaultMessage: "Mecánica",
    description: "For workshops type, when creating an order, the form",
  },
  mainteinance: {
    id: "mainteinance",
    defaultMessage: "Mantenimiento",
    description: "For workshops type, when creating an order, the form",
  },
  min_items_search_length: {
    id: "min_items_search_length",
    defaultMessage:
      "La búsqueda debe contenter al menos {minLength} caracteres",
    description: "",
  },
  mobile_phone: {
    id: "mobile_phone",
    defaultMessage: "Teléfono",
    description: "When creating an order, the data form",
  },
  mobile_phone_invalid: {
    id: "mobile_phone_invalid",
    defaultMessage: "Teléfono móvil no válido",
    description: "",
  },
  mobile_phone_placeholder: {
    id: "mobile_phone_placeholder",
    defaultMessage: " ",
    description: "",
  },
  modify_discount: {
    id: "modify_discount",
    defaultMessage: "Modificar descuento",
    description: "When creating an order, the text-link to modify the discount",
  },
  more_stores: {
    id: "more_stores",
    defaultMessage: "Ver todas las tiendas",
    description: "In the sidemenu dropdown, to change the store",
  },
  name: {
    id: "name",
    defaultMessage: "Nombre",
    description:
      "Column heading of a table, in catalogue page, settings (both store and user management), order creation page",
  },
  navigate_back_orders_list: {
    id: "navigate_back_orders_list",
    defaultMessage: "Ver listado de órdenes",
    description: "",
  },
  nie: {
    id: "nie",
    defaultMessage: "NIE",
    description: "",
  },
  nin_control_help_text1: {
    id: "nin_control_help_text1",
    defaultMessage: "El NIN control está formado por ",
    description: "Modal info about what is NIN control",
  },
  nin_control_help_text2: {
    id: "nin_control_help_text2",
    defaultMessage: "los 7 dígitos y la letra en mayúscula",
    description: "Modal info about what is NIN control",
  },
  nin_control_help_text3: {
    id: "nin_control_help_text3",
    defaultMessage: " situados en la segunda línea.",
    description: "Modal info about what is NIN control",
  },
  nin_control_help_text4: {
    id: "nin_control_help_text4",
    defaultMessage: "Documentos válidos:",
    description: "Modal info about what is NIN control",
  },
  nin_control_help_title: {
    id: "nin_control_help_title",
    defaultMessage: "¿Qué es el NIN Control?",
    description: "Modal info about what is NIN control",
  },
  nin_control_invalid: {
    id: "nin_control_invalid",
    defaultMessage: "Número de control no válido",
    description: "",
  },
  nin_control_placeholder: {
    id: "nin_control_placeholder",
    defaultMessage: " ",
    description: "When creating an order, the data form",
  },
  nin_control: {
    id: "nin_control",
    defaultMessage: "NIN control",
    description: "When creating an order, the data form",
  },
  nin_placeholder: {
    id: "nin_placeholder",
    defaultMessage: " ",
    description: "When creating an order, the data form",
  },
  nin: {
    id: "nin",
    defaultMessage: "DNI",
    description: "When creating an order, the data form",
  },
  nin_invalid: {
    id: "nin_invalid",
    defaultMessage: "DNI/NIE no válido",
    description: "",
  },
  no_items_search: {
    id: "no_items_search",
    defaultMessage: 'La búsqueda "{search}" no devolvió resultados',
    description: "",
  },
  no_orders_feedback_create_order: {
    id: "no_orders_feedback_create_order",
    defaultMessage: "Nueva venta",
    description: "",
  },
  no_orders_feedback_message1: {
    id: "no_orders_feedback_message1",
    defaultMessage: "Vaya! Parece que no hay nada",
    description: "",
  },
  no_orders_feedback_message2: {
    id: "no_orders_feedback_message2",
    defaultMessage: "Empieza añadiendo la primera venta",
    description: "",
  },
  no: {
    id: "no",
    defaultMessage: "No",
    description: "Regular No",
  },
  not_approved_status_msg1: {
    id: "not_approved_status_msg1",
    defaultMessage: "La solicitud no ha sido aprobada",
    description: "",
  },
  not_approved_status_msg2: {
    id: "not_approved_status_msg2",
    defaultMessage:
      "Puede que no hay pasado nuestros filtros o que tenga demasiadas compras activas con seQura.",
    description: "",
  },
  not_bankable: {
    id: "not_bankable",
    defaultMessage: "no financiable",
    description: "",
  },
  not_mobile_adapted_text: {
    id: "not_mobile_adapted_text",
    defaultMessage:
      "La vista preferente es desde un ordenador. Puedes seguir trabajando, pero algunas funcionalidades podrían verse afectadas.",
    description: "",
  },
  not_mobile_adapted_title: {
    id: "not_mobile_adapted_title",
    defaultMessage: "Ups! Este apartado no está preparado para móvil",
    description: "",
  },
  notfound_link_manual: {
    id: "notfound_link_manual",
    defaultMessage: "Ver manual",
    description: "",
  },
  notfound_text: {
    id: "notfound_text",
    defaultMessage:
      "Perdona las molestias, revisa que la dirección sea correcta o consulta más información en el manual",
    description: "",
  },
  notfound_title: {
    id: "notfound_title",
    defaultMessage: "No encontramos la página",
    description: "",
  },
  offline: {
    id: "offline",
    defaultMessage: "Parece que no tienes conexión a internet",
    description: "",
  },
  ooops: {
    id: "ooops",
    defaultMessage: "Ooops! Algo ha salido mal",
    description: "",
  },
  operation_type_placeholder: {
    id: "operation_type_placeholder",
    defaultMessage: " ",
    description: "",
  },
  operation_type: {
    id: "operation_type",
    defaultMessage: "Operación",
    description: "",
  },
  operation_type_invalid: {
    id: "operation_type_invalid",
    defaultMessage: "Operación no válida",
    description: "For workshops type, when creating an order, the form",
  },
  operator_ref: {
    id: "operator_ref",
    defaultMessage: "Código agente",
    description: "When creating an order, the data form",
  },
  operator_ref_error: {
    id: "operator_ref_error",
    defaultMessage: "Error en código agente",
    description: "When creating an order, the data form",
  },
  operator_ref_placeholder: {
    id: "operator_ref_placeholder",
    defaultMessage: "Código de agente",
    description: "When creating an order, the data form",
  },
  order_already_solicited: {
    id: "order_already_solicited",
    defaultMessage: "La compra ya ha sido iniciada por el cliente",
    description: "",
  },
  order_create_error: {
    id: "order_create_error",
    defaultMessage: "Teléfono móvil no válido",
    description: "",
  },
  order_create_invalid_amount_error: {
    id: "order_create_invalid_amount_error",
    defaultMessage: "El importe no es válido",
    description: "",
  },
  order_detail_status_cancelled: {
    id: "order_detail_status_cancelled",
    defaultMessage: "Se ha procedido con la devolución del total",
    description: "",
  },
  order_detail_status_confirmed: {
    id: "order_detail_status_confirmed",
    defaultMessage: "¡Listo! La venta ha sido confirmada",
    description: "",
  },
  order_detail_status_error: {
    id: "order_detail_status_error",
    defaultMessage: "No se ha podido completar, ponte en contacto",
    description: "",
  },
  order_detail_status_in_progress: {
    id: "order_detail_status_in_progress",
    defaultMessage: "El cliente ya ha empezado el proceso",
    description: "",
  },
  order_detail_status_inactive: {
    id: "order_detail_status_inactive",
    defaultMessage: "El cliente no ha completado el proceso",
    description: "",
  },
  order_detail_status_not_approved: {
    id: "order_detail_status_not_approved",
    defaultMessage: "La solicitud no ha sido aprobada",
    description: "",
  },
  order_detail_status_pending: {
    id: "order_detail_status_pending",
    defaultMessage: "SMS enviado al número de teléfono indicado",
    description: "",
  },
  order_detail_status_reimbursed: {
    id: "order_detail_status_reimbursed",
    defaultMessage: "Se ha procedido con la devolución del total",
    description: "",
  },
  order_detail_status_title: {
    id: "order_detail_status_title",
    defaultMessage: "Estado:",
    description: "",
  },
  order_max_resets_reached: {
    id: "order_max_resets_reached",
    defaultMessage: "La venta ya ha sido reseteada",
    description: "",
  },
  order_not_cancelable: {
    id: "order_not_cancelable",
    defaultMessage: "La venta no puede ser cancelada",
    description: "",
  },
  order_status_no_more_sms: {
    id: "order_status_no_more_sms",
    defaultMessage: "No tienes más intentos",
    description: "",
  },
  orders_count: {
    id: "orders_count",
    defaultMessage: "Total órdenes",
    description: "",
  },
  other_payment: {
    id: "other_payment",
    defaultMessage: "Otros pagos",
    description: "",
  },
  other_payment_modify: {
    id: "other_payment_modify",
    defaultMessage: "Modificar otros pagos",
    description: "Modify an other payment method in cart/create order",
  },
  pagetitle_catalogue: {
    id: "pagetitle_catalogue",
    defaultMessage: "Catálogo",
    description: "Header menu item",
  },
  pagetitle_create: {
    id: "pagetitle_create",
    defaultMessage: "Nueva venta",
    description: "Sidemenu item",
  },
  pagetitle_home: {
    id: "pagetitle_home",
    defaultMessage: "Simulador",
    description: "Sidemenu item",
  },
  pagetitle_list: {
    id: "pagetitle_list",
    defaultMessage: "Listado de ventas",
    description: "Sidemenu item",
  },
  pagetitle_order_status: {
    id: "pagetitle_order_status",
    defaultMessage: "Estado de la orden",
    description: "Sidemenu item?",
  },
  pagetitle_settings: {
    id: "pagetitle_settings",
    defaultMessage: "Configuración",
    description: "Header menu item",
  },
  pagination_per_page: {
    id: "pagination_per_page",
    defaultMessage: "Filas por página",
    description: "",
  },
  pagination_range: {
    id: "pagination_range",
    defaultMessage: "{first}-{last} de {total}",
    description: "",
  },
  paint_job: {
    id: "paint_job",
    defaultMessage: "Chapa y pintura",
    description: "For workshops type, when creating an order, the form",
  },
  passport: {
    id: "passport",
    defaultMessage: "Pasaporte",
    description: "",
  },
  password_invalid_authentication: {
    id: "password_invalid_authentication",
    defaultMessage: "Email o password incorrectos",
    description: "",
  },
  pending_status_msg1: {
    id: "pending_status_msg1",
    defaultMessage: "Esperando confirmación",
    description: "",
  },
  pending_status_msg2: {
    id: "pending_status_msg2",
    defaultMessage:
      "Se ha enviado un SMS al cliente para que complete sus datos.",
    description: "",
  },
  pin_invalid_authentication: {
    id: "pin_invalid_authentication",
    defaultMessage: "El código no es válido",
    description: "",
  },
  plate_placeholder: {
    id: "plate_placeholder",
    defaultMessage: " ",
    description: "",
  },
  plate: {
    id: "plate",
    defaultMessage: "Matrícula",
    description: "",
  },
  plate_error: {
    id: "plate_error",
    defaultMessage: "Matrícula no válida",
    description: "",
  },
  postal_code_error: {
    id: "postal_code_error",
    defaultMessage: "Introduce un código postal válido. Ejemplo: 08010",
    description: "",
  },
  postal_code_placeholder: {
    id: "postal_code_placeholder",
    defaultMessage: " ",
    description: "When creating an order, the data form",
  },
  postal_code: {
    id: "postal_code",
    defaultMessage: "Código postal",
    description: "When creating an order, the data form",
  },
  price: {
    id: "price",
    defaultMessage: "Precio",
    description: "",
  },
  product_search_feedback_empty: {
    id: "product_search_feedback_empty",
    defaultMessage:
      "No se ha encontrado ningún artículos, prueba con otra palabra o referencia",
    description: "",
  },
  product_search_feedback_loading: {
    id: "product_search_feedback_loading",
    defaultMessage: "Cargando...",
    description: "",
  },
  product_search_feedback_mintext: {
    id: "product_search_feedback_mintext",
    defaultMessage: "Escribe al menos 3 caracteres",
    description: "",
  },
  quantity: {
    id: "quantity",
    defaultMessage: "Cantidad",
    description: "",
  },
  registration: {
    id: "registration",
    defaultMessage: "Matrícula",
    description: "Registration fee of a course (related to education)",
  },
  registration_modify: {
    id: "registration_modify",
    defaultMessage: "Modificar matrícula",
    description: "Modify a registration fee of a course (related to education)",
  },
  reference: {
    id: "reference",
    defaultMessage: "Referencia",
    description: "Title of a column table",
  },
  reference_error: {
    id: "reference_error",
    defaultMessage:
      "La referencia no puede contener el carácter '/' ni espacios",
    description: "",
  },
  reference_taken: {
    id: "reference_taken",
    defaultMessage: "Referencia en uso",
    description: "",
  },
  reference_placeholder: {
    id: "reference_placeholder",
    defaultMessage: " ",
    description: "",
  },
  reference_sale: {
    id: "reference_sale",
    defaultMessage: "Referencia de venta",
    description: "When creating an order, the data form",
  },
  remove_item: {
    id: "remove_item",
    defaultMessage: "Eliminar",
    description: "",
  },
  resend_order: {
    id: "resend_order",
    defaultMessage: "Repetir venta",
    description: "",
  },
  resend_sms: {
    id: "resend_sms",
    defaultMessage: "Reenviar SMS",
    description: "",
  },
  save: {
    id: "save",
    defaultMessage: "Guardar",
    description: "",
  },
  search_catalogue: {
    id: "search_catalogue",
    defaultMessage: "Busca por nombre o referencia...",
    description: "",
  },
  search_empty_orders: {
    id: "search_empty_orders",
    defaultMessage:
      "Ninguna venta cumple el criterio de búsqueda, comprueba el teléfono o referencia",
    description: "",
  },
  search_list_orders: {
    id: "search_list_orders",
    defaultMessage: "Busca por referencia o número de teléfono...",
    description: "",
  },
  select_store_access_button: {
    id: "select_store_access_button",
    defaultMessage: "Acceder",
    description: "",
  },
  select_store_code: {
    id: "select_store_code",
    defaultMessage: "Código tienda",
    description: "",
  },
  select_store_filter_input: {
    id: "select_store_filter_input",
    defaultMessage: "Filtra por nombre o referencia de la tienda",
    description: "",
  },
  select_store_logout: {
    id: "select_store_logout",
    defaultMessage: "Cambiar",
    description: "",
  },
  select_store_no_results: {
    id: "select_store_no_results",
    defaultMessage: "No encontramos la tienda que buscas",
    description: "",
  },
  select_store_registered_as: {
    id: "select_store_registered_as",
    defaultMessage: "Te has registrado como:",
    description: "",
  },
  select_store_title1: {
    id: "select_store_title1",
    defaultMessage: "Selecciona una tienda para empezar",
    description: "",
  },
  select_store_title2: {
    id: "select_store_title2",
    defaultMessage: "Accede a la tienda en la que vas a trabajar hoy",
    description: "",
  },
  session_expired: {
    id: "session_expired",
    defaultMessage:
      "Tu sesión ha caducado. Por favor, reintroduce tus credenciales",
    description: "",
  },
  setup_fee: {
    id: "setup_fee",
    defaultMessage: "Costes de apertura",
    description: "",
  },
  setup_fee_modify: {
    id: "setup_fee_modify",
    defaultMessage: "Modificar costes de apertura",
    description: "Modify setup fee in cart/create order",
  },
  settings_add_store_helper: {
    id: "settings_add_store_helper",
    defaultMessage: "Empieza añadiendo tiendas",
    description: "",
  },
  settings_add_store_title: {
    id: "settings_add_store_title",
    defaultMessage: "Creación de nueva tienda",
    description: "",
  },
  settings_add_store: {
    id: "settings_add_store",
    defaultMessage: "Crear tienda",
    description: "",
  },
  settings_add_user_helper: {
    id: "settings_add_user_helper",
    defaultMessage: "Empieza añadiendo cuentas de usuario",
    description: "",
  },
  settings_add_user_title: {
    id: "settings_add_user_title",
    defaultMessage: "Creación de una nueva cuenta de usuario",
    description: "",
  },
  settings_add_user: {
    id: "settings_add_user",
    defaultMessage: "Crear cuenta",
    description: "",
  },
  settings_assign_users_store: {
    id: "settings_assign_users_store",
    defaultMessage: "Gestionar accesos a “{storeName}”",
    description: "",
  },
  settings_change_password: {
    id: "settings_change_password",
    defaultMessage: "Cambiar contraseña",
    description: "",
  },
  settings_delete_store_confirm: {
    id: "settings_delete_store_confirm",
    defaultMessage: "¿Seguro que quieres eliminar la tienda?",
    description: "",
  },
  settings_delete_store_disabled: {
    id: "settings_delete_store_disabled",
    defaultMessage:
      "No se puede borrar la última tienda y/o con órdenes creadas",
    description: "",
  },
  settings_delete_store: {
    id: "settings_delete_store",
    defaultMessage: "Borrar tienda",
    description: "",
  },
  settings_delete_user_confirm: {
    id: "settings_delete_user_confirm",
    defaultMessage: "¿Seguro que quieres eliminar esta cuenta?",
    description: "",
  },
  settings_delete_user: {
    id: "settings_delete_user",
    defaultMessage: "Borrar cuenta",
    description: "",
  },
  settings_empty_users: {
    id: "settings_empty_users",
    defaultMessage: "Aún no se han creado cuentas",
    description: "",
  },
  settings_error_deactivate_current_store: {
    id: "settings_error_deactivate_current_store",
    defaultMessage: "No puedes desactivar la tienda en la que estás trabajando",
    description: "",
  },
  settings_manage_users: {
    id: "settings_manage_users",
    defaultMessage: "Gestionar accesos",
    description: "",
  },
  settings_no_assigned_users: {
    id: "settings_no_assigned_users",
    defaultMessage: "No hay cuentas asociadas esta tienda",
    description: "",
  },
  settings_password_validate: {
    id: "settings_password_validate",
    defaultMessage: "La contraseña debe contener al menos 5 caracteres",
    description: "",
  },
  settings_store_linked_users: {
    id: "settings_store_linked_users",
    defaultMessage: "Personal con acceso a la tienda",
    description: "",
  },
  settings_store_status_active: {
    id: "settings_store_status_active",
    defaultMessage: "Activa",
    description: "",
  },
  settings_store_status_inactive: {
    id: "settings_store_status_inactive",
    defaultMessage: "Inactiva",
    description: "",
  },
  settings_tab_stores: {
    id: "settings_tab_stores",
    defaultMessage: "Tiendas",
    description: "To manage stores in settings page",
  },
  settings_tab_users: {
    id: "settings_tab_users",
    defaultMessage: "Cuentas",
    description: "To manage users in settings page",
  },
  settings_user_assigned: {
    id: "settings_user_assigned",
    defaultMessage: "Has añadido una cuenta",
    description: "",
  },
  settings_user_unassigned: {
    id: "settings_user_unassigned",
    defaultMessage: "Has eliminado una cuenta",
    description: "",
  },
  shopper_cart_status_cancelled_button: {
    id: "shopper_cart_status_cancelled_button",
    defaultMessage: "Ver preguntas frecuentes",
    description: "",
  },
  shopper_cart_status_cancelled_description: {
    id: "shopper_cart_status_cancelled_description",
    defaultMessage:
      "Se ha procedido con la devolución del importe total. Para más información revisa nuestras preguntas frecuentes.",
    description: "",
  },
  shopper_cart_status_cancelled_title: {
    id: "shopper_cart_status_cancelled_title",
    defaultMessage: "Tu compra está reembolsada",
    description: "",
  },
  shopper_cart_status_confirmed_button: {
    id: "shopper_cart_status_confirmed_button",
    defaultMessage: "Ver mi cuenta",
    description: "",
  },
  shopper_cart_status_confirmed_description: {
    id: "shopper_cart_status_confirmed_description",
    defaultMessage:
      "El pago ha sido aprobado. Recuerda que puedes acceder a tu cuenta personal para revisar tus pagos.",
    description: "",
  },
  shopper_cart_status_confirmed_title: {
    id: "shopper_cart_status_confirmed_title",
    defaultMessage: "Tu compra está confirmada",
    description: "",
  },
  shopper_cart_status_error_description: {
    id: "shopper_cart_status_error_description",
    defaultMessage:
      "El sistema no está disponible, disculpa las molestias. Puedes hablar con el personal de la tienda.",
    description: "",
  },
  shopper_cart_status_error_title: {
    id: "shopper_cart_status_error_title",
    defaultMessage: "Esta compra no está disponible",
    description: "",
  },
  shopper_cart_status_on_hold_button: {
    id: "shopper_cart_status_on_hold_button",
    defaultMessage: "Ver mi cuenta",
    description: "",
  },
  shopper_cart_status_on_hold_description: {
    id: "shopper_cart_status_on_hold_description",
    defaultMessage:
      "Se ha procedido con la validación de tu compra. Pronto estaremos en contacto para los próximos pasos.",
    description: "",
  },
  shopper_cart_status_on_hold_title: {
    id: "shopper_cart_status_on_hold_title",
    defaultMessage: "Tu compra está en validación",
    description: "",
  },
  shopper_fp1_button: {
    id: "shopper_fp1_button",
    defaultMessage: "Pagar ahora",
    description: "",
  },
  shopper_payment_options: {
    id: "shopper_payment_options",
    defaultMessage: "Info Opciones de pago",
    description: "",
  },
  shopper_payment_options_subtitle: {
    id: "shopper_payment_options_subtitle",
    defaultMessage: "Continúa al siguiente paso para seleccionar el plan",
    description: "",
  },
  shopper_pp_button: {
    id: "shopper_pp_button",
    defaultMessage: "Continuar",
    description: "",
  },
  shopper_pp_button_many: {
    id: "shopper_pp_button_many",
    defaultMessage: "Seleccionar",
    description: "",
  },
  shopper_quantity_suffix: {
    id: "shopper_quantity_suffix",
    defaultMessage: "{quantity, plural, one {unidad} other {unidades}}",
    description: "",
  },
  shopper_sp1_button: {
    id: "shopper_sp1_button",
    defaultMessage: "Dividir en 3",
    description: "",
  },
  sidemenu_logout: {
    id: "sidemenu_logout",
    defaultMessage: "Cerrar sesión",
    description: "",
  },
  simulator_below_min: {
    id: "simulator_below_min",
    defaultMessage: "Sólo disponible para compras superiores a {min}",
    description: "",
  },
  simulator_instalments: {
    id: "simulator_instalments",
    defaultMessage: "{instalments} plazos",
    description: "",
  },
  simulator_fp1_heading: {
    id: "simulator_fp1_heading",
    defaultMessage: "Paga ahora",
    description: "",
  },
  simulator_fp1: {
    id: "simulator_fp1",
    defaultMessage: "Pago con tarjeta {total}",
    description: "",
  },
  simulator_not_available: {
    id: "simulator_not_available",
    defaultMessage:
      "Lo sentimos, no hemos podido cargar la información del simulador. Prueba a recargar la página",
    description: "",
  },
  simulator_pp_after_pay: {
    id: "simulator_pp_after_pay",
    defaultMessage: "Después pagarás",
    description: "",
  },
  simulator_pp_first_not_financiable: {
    id: "simulator_pp_first_not_financiable",
    defaultMessage: "Primera cuota + no financiable",
    description: "",
  },
  simulator_pp_heading: {
    id: "simulator_pp_heading",
    defaultMessage: "Pago a plazos",
    description: "",
  },
  simulator_pp_item: {
    id: "simulator_pp_item",
    defaultMessage: "{instalment_count} cuotas de {instalment_total}",
    description: "",
  },
  simulator_pp_over_max: {
    id: "simulator_pp_over_max",
    defaultMessage:
      "Máximo financiable {max} €, la diferencia se cobra en la 1ª cuota.",
    description: "",
  },
  simulator_pp_total: {
    id: "simulator_pp_total",
    defaultMessage: "Total",
    description: "",
  },
  simulator_pp3_intro: {
    id: "simulator_pp3_intro",
    defaultMessage:
      "Fracciona el pago por un único coste de {instalment_fee} €/plazo. {overMax}",
    description: "",
  },
  simulator_pp6_intro: {
    id: "simulator_pp6_intro",
    defaultMessage: "Fracciona completamente gratis. {overMax}",
    description: "",
  },
  simulator_pp9_intro: {
    id: "simulator_pp9_intro",
    defaultMessage:
      "Fracciona el pago por un único coste de {instalment_fee} €/plazo. {overMax}",
    description: "",
  },
  simulator_sp1_3payments: {
    id: "simulator_sp1_3payments",
    defaultMessage: "3 pagos de ",
    description: "",
  },
  simulator_sp1_heading: {
    id: "simulator_sp1_heading",
    defaultMessage: "Divide el pago en 3",
    description: "",
  },
  simulator_sp1_over_max: {
    id: "simulator_sp1_over_max",
    defaultMessage: "Sólo disponible para compras inferiores a {max}",
    description: "",
  },
  simulator_sp3_free_instalment: {
    id: "simulator_sp3_free_instalment",
    defaultMessage: "Sin coste",
    description: "",
  },
  simulator_sp3_with_instalment: {
    id: "simulator_sp3_with_instalment",
    defaultMessage: "con sólo un coste de apertura de {instalment_amount}",
    description: "",
  },
  sms_error: {
    id: "sms_error",
    defaultMessage:
      "El mensaje no se ha podido enviar, revisa el número de teléfono.",
    description: "",
  },
  status_cancelled: {
    id: "status_cancelled",
    defaultMessage: "Reembolsada", // New status, change name
    description: "",
  },
  status_confirmed: {
    id: "status_confirmed",
    defaultMessage: "Confirmada",
    description: "",
  },
  status_error: {
    id: "status_error",
    defaultMessage: "Error",
    description: "",
  },
  status_in_progress: {
    id: "status_in_progress",
    defaultMessage: "En progreso",
    description: "",
  },
  status_inactive: {
    id: "status_inactive",
    defaultMessage: "Inactiva",
    description: "",
  },
  status_not_approved: {
    id: "status_not_approved",
    defaultMessage: "No aprobada",
    description: "",
  },
  status_pending: {
    id: "status_pending",
    defaultMessage: "Enviada",
    description: "",
  },
  status_reimbursed: {
    id: "status_reimbursed",
    defaultMessage: "Reembolsada",
    description: "",
  },
  status: {
    id: "status",
    defaultMessage: "Estado",
    description: "",
  },
  stores: {
    id: "stores",
    defaultMessage: "Tiendas",
    description: "",
  },
  submit_add_product: {
    id: "submit_add_product",
    defaultMessage: "Crear producto",
    description: "",
  },
  submit_create_order: {
    id: "submit_create_order",
    defaultMessage: "Añadir venta",
    description: "",
  },
  submit_login: {
    id: "submit_login",
    defaultMessage: "Entrar",
    description: "",
  },
  subtotal: {
    id: "subtotal",
    defaultMessage: "Subtotal",
    description: "Price of a cart before discounts and other payments",
  },
  surnames_placeholder: {
    id: "surnames_placeholder",
    defaultMessage: " ",
    description: "When creating an order, the data form",
  },
  surnames: {
    id: "surnames",
    defaultMessage: "Apellidos",
    description: "When creating an order, the data form",
  },
  switch_store: {
    id: "switch_store",
    defaultMessage: "Cambiar tienda",
    description: "In the sidemenu, a dropdown to change store",
  },
  table_loading: {
    id: "table_loading",
    defaultMessage: "Cargando...",
    description: "",
  },
  tires: {
    id: "tires",
    defaultMessage: "Neumáticos",
    description: "For workshops type, when creating an order, the form",
  },
  total: {
    id: "total",
    defaultMessage: "Total",
    description:
      "Total regarding to an amount, after any discount applied and with taxes",
  },
  total_finance: {
    id: "total_finance",
    defaultMessage: "Total a financiar",
    description: "Total label on Other Payments modal",
  },
  total_with_tax: {
    id: "total_with_tax",
    defaultMessage: "Importe",
    description: "Detail view of an order, the info",
  },
  upload_file: {
    id: "upload_file",
    defaultMessage: "Subir un fichero",
    description: "",
  },
  upload_status_footer_general: {
    id: "upload_status_footer_general",
    defaultMessage: "Error general",
    description: "",
  },
  upload_status_footer_multiple: {
    id: "upload_status_footer_multiple",
    defaultMessage: "Error múltiple",
    description: "",
  },
  upload_status_footer_multiple2: {
    id: "upload_status_footer_multiple2",
    defaultMessage:
      "Revisa estos errores y dónde se repiten y vuelve a subir el fichero.",
    description: "",
  },
  upload_status_general_error_feedback_empty_file: {
    id: "upload_status_general_error_feedback_empty_file",
    defaultMessage:
      "El fichero está vacío (no hay artículos). Revísalo y vuelve a subir el fichero.",
    description: "",
  },
  upload_status_general_error_feedback_missing_fields: {
    id: "upload_status_general_error_feedback_missing_fields",
    defaultMessage:
      "Faltan los campos obligatorios. Revísalo y vuelve a subir el fichero.",
    description: "",
  },
  upload_status_go_documentation: {
    id: "upload_status_go_documentation",
    defaultMessage: "Ver documentación",
    description: "",
  },
  upload_status_line_error_feedback: {
    id: "upload_status_line_error_feedback",
    defaultMessage: "El campo {field} no es válido",
    description: "",
  },
  upload_status_line_error_Name: {
    id: "upload_status_line_error_Name",
    defaultMessage: "nombre",
    description: "",
  },
  upload_status_line_error_Price: {
    id: "upload_status_line_error_Price",
    defaultMessage: "precio",
    description: "",
  },
  upload_status_line_error_Reference: {
    id: "upload_status_line_error_Reference",
    defaultMessage: "referencia",
    description: "",
  },
  upload_status_line_help_Name_blank: {
    id: "upload_status_line_help_Name_blank",
    defaultMessage: "El nombre no puede estar vacío",
    description: "",
  },
  upload_status_line_help_Price_blank: {
    id: "upload_status_line_help_Price_blank",
    defaultMessage: "El precio no puede estar vacío",
    description: "",
  },
  upload_status_line_help_Price_format: {
    id: "upload_status_line_help_Price_format",
    defaultMessage:
      "Los precios deben ser en céntimos sin decimales: 19,00 € = 1900",
    description: "",
  },
  upload_status_line_help_Reference_blank: {
    id: "upload_status_line_help_Reference_blank",
    defaultMessage: "La referencia no puede estar vacía",
    description: "",
  },
  upload_status_line_number_error: {
    id: "upload_status_line_number_error",
    defaultMessage: "Error en la fila",
    description: "",
  },
  upload_status_max_errors_reached: {
    id: "upload_status_max_errors_reached",
    defaultMessage:
      "Solo se muestran los {n} primeros errores, revísalos y vuelve a subir el fichero.",
    description: "",
  },
  upload_status_message_error_general: {
    id: "upload_status_message_error_general",
    defaultMessage:
      "Error en el fichero, no se han podido añadir artículos al catálogo.",
    description: "",
  },
  upload_status_message_error_multiple: {
    id: "upload_status_message_error_multiple",
    defaultMessage:
      "Múltiples errores, no se han podido añadir algunos artículos.",
    description: "",
  },
  upload_status_message_ok: {
    id: "upload_status_message_ok",
    defaultMessage: "¡Listo! Se han creado todos los artículos con éxito.",
    description: "",
  },
  upload_status_message_pending: {
    id: "upload_status_message_pending",
    defaultMessage: "Se están creando las fichas de artículo.",
    description: "",
  },
  upload_status_number_rows: {
    id: "upload_status_number_rows",
    defaultMessage: "Subidos",
    description: "",
  },
  users_count: {
    id: "users_count",
    defaultMessage: "Total cuentas",
    description: "",
  },
  validator_adult: {
    id: "validator_adult",
    defaultMessage: "Fecha de nacimiento no válida. Debe ser mayor de 18 años",
    description: "",
  },
  validator_amount: {
    id: "validator_amount",
    defaultMessage: "Introduce una cantidad correcta. Ejemplo: 100,0",
    description: "",
  },
  validator_date: {
    id: "validator_date",
    defaultMessage: "El formato de la fecha es inválido. Ejemplo: 20/10/2000",
    description: "",
  },
  validator_email: {
    id: "validator_email",
    defaultMessage: "No es un correo electrónico",
    description: "",
  },
  validator_pin: {
    id: "validator_pin",
    defaultMessage: "No es un código de 6 dígitos",
    description: "",
  },
  validator_required: {
    id: "validator_required",
    defaultMessage: "Campo requerido",
    description: "When creating an order, the data form",
  },
  view_detail: {
    id: "view_detail",
    defaultMessage: "Ver detalle",
    description: "",
  },
  widget_error_card_button: {
    id: "widget_error_card_button",
    defaultMessage: "Recargar la página",
    description: "",
  },
  widget_error_card_description: {
    id: "widget_error_card_description",
    defaultMessage:
      "Tienes que refrescar la página para ver las opciones de pago",
    description: "",
  },
  widget_error_card_title: {
    id: "widget_error_card_title",
    defaultMessage: "Ups! Ha habido un error",
    description: "",
  },
  yes: {
    id: "yes",
    defaultMessage: "Sí",
    description: "Regular Yes",
  },
});
