import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { capitalizeWord } from "../utils";
import Text from "./Text";

const Wrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  & > div {
    flex-direction: row;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  .TotcalCart {
    margin: 0.5rem 0;
  }
`;

const TotalCartDiscount = ({
  intl,
  locales,
  quantity,
  subtotal,
  discount,
  otherPayment = {},
  handling = {},
  registration = {},
  setup_fee,
  totalAmount,
  error,
}) => (
  <Wrap>
    {(discount ||
      otherPayment.total_with_tax ||
      handling.total_with_tax ||
      registration.total_with_tax ||
      setup_fee.total_with_tax?.value) && (
      <div>
        <div>
          <Text body2 secondary>
            {intl.formatMessage(locales.subtotal)}
          </Text>{" "}
        </div>
        <div>
          <Text body2 right secondary error={error}>
            {subtotal}
          </Text>
        </div>
      </div>
    )}
    {discount ? (
      <div data-testid="CartSummary_Discount">
        <div>
          <Text body2 secondary>
            {intl.formatMessage(locales.discount)}
          </Text>
        </div>
        <div>
          <Text body2 secondary right>
            -{discount}
          </Text>
        </div>
      </div>
    ) : null}
    {handling.total_with_tax && (
      <div data-testid="CartSummary_OtherPayment">
        <Text body2 secondary>
          {intl.formatMessage(locales.handling)}
        </Text>
        <Text body2 secondary right>
          {handling.total_with_tax}
        </Text>
      </div>
    )}
    {registration.total_with_tax && (
      <div data-testid="CartSummary_OtherPayment">
        <Text body2 secondary>
          {intl.formatMessage(locales.registration)}
        </Text>
        <Text body2 secondary right>
          {registration.total_with_tax}
        </Text>
      </div>
    )}
    {setup_fee.total_with_tax?.value && (
      <div data-testid="CartSummary_OtherPayment">
        <Text body2 secondary>
          {intl.formatMessage(locales.setup_fee)}
        </Text>
        <Text body2 secondary right>
          {setup_fee.total_with_tax.string}
        </Text>
      </div>
    )}
    {otherPayment.total_with_tax && (
      <div data-testid="CartSummary_OtherPayment">
        <Text body2 secondary>
          {intl.formatMessage(locales.other_payment)}:
          {` ${capitalizeWord(otherPayment.name)}`}
        </Text>
        <Text body2 secondary right>
          -{otherPayment.total_with_tax}
        </Text>
      </div>
    )}
    <div className="TotcalCart">
      <div>
        <Text body2>{intl.formatMessage(locales.total)}</Text>{" "}
        <Text body2>
          {intl.formatMessage(locales.discount_total_quantity, { quantity })}
        </Text>
      </div>
      {totalAmount && (
        <div data-testid="CartSummary_Total" className="CartSummary_Total">
          <Text right visual2 error={error}>
            {totalAmount}
          </Text>
        </div>
      )}
    </div>
  </Wrap>
);

TotalCartDiscount.propTypes = {
  intl: PropTypes.object,
  locales: PropTypes.object.isRequired,
  quantity: PropTypes.number,
  subtotal: PropTypes.string,
  discount: PropTypes.string,
  otherPayment: PropTypes.object,
  handling: PropTypes.object,
  registration: PropTypes.object,
  setup_fee: PropTypes.object,
  totalAmount: PropTypes.string,
  error: PropTypes.bool,
};

TotalCartDiscount.defaultProps = {
  intl: {
    formatMessage: (text) => text,
  },
  quantity: 0,
  subtotal: "0,00 €",
  discount: null,
  otherPayment: {},
  handling: {},
  registration: {},
  setup_fee: {},
  totalAmount: null,
  error: false,
};

export default TotalCartDiscount;
