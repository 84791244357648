import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Modal as AntModal } from "antd";
import PropTypes from "prop-types";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

const GlobalModalWrapStyle = createGlobalStyle`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    .ant-modal-wrap {
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      & > div{
        flex: 1;
      }
    }
  }
`;

const StyledModal = styled(AntModal)`
  & .ant-modal-content {
    max-width: 448px;
    margin: 0 auto;
    border-radius: 4px;
    box-shadow: none;
    & > .ant-modal-close {
      transition: none;
      & > .ant-modal-close-x {
        margin-top: 12px;
        margin-right: 4px;
        & path {
          fill: ${({ theme }) => theme.palette.grey.primary};
        }
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    max-width: calc(100vw - 32px);
    max-height: calc(100vh - 32px);
    margin: 16px auto;
    top: 0;
    & .ant-modal-body {
      max-height: calc(100vh - 32px);
    }
  }
`;

const Modal = ({ visible, onCancel, children, ...props }) => {
  return (
    <StyledModal
      role="dialog"
      data-testid="modal"
      closeIcon={<CloseRoundedIcon />}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      {...props}
    >
      {children}
      <GlobalModalWrapStyle />
    </StyledModal>
  );
};

Modal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  children: PropTypes.node,
};

Modal.defaultProps = {
  visible: true,
  onCancel: () => null,
  children: null,
};

export default Modal;
