export const amountToCents = (amount) => {
  const [integer, decimal] = amount.toString().replace(".", "").split(",");
  let result = integer + (decimal || "");
  if (!decimal) {
    result += "00";
  } else if (decimal.length === 1) {
    result += "0";
  }
  return parseInt(result, 10);
};

export const centsToAmount = (cents) => {
  const integer = Math.floor(cents / 100)
    .toString()
    .replace(/(\d)(?=(?:\d{3})+(?:\.|$))|(\.\d\d?)\d*$/g, "$1.");
  let decimal = cents % 100;
  if (decimal === 0) {
    decimal = "00";
  } else if (decimal < 10) {
    decimal = `0${decimal}`;
  }
  return `${integer},${decimal}`;
};

export const isAmountString = (str) =>
  /^0*\d{1,3}((\.\d{3})|\d)*($|,\d{0,2}$)/.test(str);

export const sortMethods = {
  number: (attr) => (a, b) => a[attr] - b[attr],
  string: (attr) => (a, b) => a[attr].localeCompare(b[attr]),
};

export const readFileObject = (file, maxFileSizeMb = 1, dataURL = false) =>
  new Promise((resolve, reject) => {
    const reader = new window.FileReader();
    const { size, name } = file;
    if (size > maxFileSizeMb * 1024 * 1024) {
      reject("exceed_max_filesize");
      return;
    }
    reader.onload = (ev) => {
      resolve({
        name,
        content: ev.target.result,
      });
    };
    reader.onerror = (error) => reject(error);
    reader[dataURL ? "readAsDataURL" : "readAsText"](file);
  });

export const pickFile = (accept = false, maxFileSizeMb = 1, dataURL = false) =>
  new Promise((resolve, reject) => {
    const input = document.createElement("input");
    const onChange = async () => {
      try {
        const result = await readFileObject(
          input.files[0],
          maxFileSizeMb,
          dataURL
        );
        resolve(result);
      } catch (e) {
        reject(e);
      } finally {
        input.removeEventListener("change", onChange);
        input.remove();
      }
    };
    input.type = "file";
    if (accept) {
      input.accept = accept;
    }
    input.style.display = "none";
    input.addEventListener("change", onChange);
    document.body.appendChild(input);
    input.click();
  });

export const dateAutoAddSlash = (value) => {
  const checkValue = (str, max) => {
    if (str.charAt(0) !== "0" || str === "00") {
      let num = parseInt(str, 10);
      if (Number.isNaN(num) || num <= 0 || num > max) {
        num = 1;
      }
      return num > parseInt(max.toString().charAt(0), 10) &&
        num.toString().length === 1
        ? `0${num}`
        : num.toString();
    }
    return str;
  };

  let normalized = value.replace(/\/+/g, "/");
  if (/\D\/$/.test(normalized)) {
    normalized = normalized.substr(0, value.length - 3);
  }
  const values = normalized.split("/").map((v) => v.replace(/\D/g, ""));
  if (values[0]) {
    values[0] = checkValue(values[0], 31);
  }
  if (values[1]) {
    values[1] = checkValue(values[1], 12);
  }
  return values.map((v, i) => (v.length === 2 && i < 2 ? `${v}/` : v)).join("");
};

export const capitalizeWord = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const prefetchImage = (url) =>
  new Promise((resolve) => {
    const img = new window.Image();
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
    img.src = url;
  });

export const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    window.location.hostname === "[::1]" ||
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export const supportedLocales = {
  es: "es-ES",
  "es-ES": "es-ES",
  "es-CO": "es-ES",
  "es-PE": "es-ES",
  fr: "fr-FR",
  "fr-FR": "fr-FR",
  it: "it-IT",
  "it-IT": "it-IT",
  pt: "pt-PT",
  "pt-PT": "pt-PT",
};
