import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import getUserLocale from "get-user-locale";
import { AuthProvider } from "./providers/Auth";
import { FeedbackProvider } from "./providers/Feedback";
import { APIProvider } from "./providers/API";
import { OrdersProvider } from "./providers/Orders";
import { WidgetSimulatorProvider } from "./providers/WidgetSimulator";
import { ShopperCartProvider } from "./providers/ShopperCart";
import { StyleProvider } from "./providers/Style";
import Router from "./providers/Router";
import getMessagesForLocale from "./locales/index";
import { supportedLocales } from "./utils";

const defaultLocale = supportedLocales[getUserLocale()] || supportedLocales.es;

const App = () => {
  const [locale, setLocale] = useState(defaultLocale);
  const handleChangeLocale = (merchantLocale) => {
    const newLocale = merchantLocale;
    setLocale(newLocale);
  };
  window.locale = locale;
  const messages = getMessagesForLocale(locale);

  return (
    <IntlProvider locale={locale} defaultLocale="es-ES" messages={messages}>
      <AuthProvider>
        <FeedbackProvider>
          <APIProvider>
            <StyleProvider>
              <OrdersProvider>
                <WidgetSimulatorProvider>
                  <ShopperCartProvider handleChangeLocale={handleChangeLocale}>
                    <Router />
                  </ShopperCartProvider>
                </WidgetSimulatorProvider>
              </OrdersProvider>
            </StyleProvider>
          </APIProvider>
        </FeedbackProvider>
      </AuthProvider>
    </IntlProvider>
  );
};

export default App;
