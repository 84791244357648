import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { useMedia, useScrollbarWidth } from "react-use";
import "antd/dist/antd.css";

const StyleContext = createContext();

const DEFAULT_THEME = {
  palette: {
    grey: {
      primary: "#1c1c1c",
      secondary: "#606060",
      muted: "#8e8e8e",
      shade: "#dddbe0",
      bg: "#f7f7f7",
      neutral: "#f4f4f4",
    },
    interactive: {
      primaryPressed: "#333333",
      primaryPressedOutline: "#e8e8e8",
      primaryDisabled: "#d2d2d2",
    },
    accent: "#00c2a3",
    accentDark: "#00c2a3",
    accentLight: "#f2fcfa",
    actionBg: "#d2d2d2",
    error: "#d00000",
    errorDark: "#b50000",
    errorBg: "#d00000",
    errorLight: "#fdf2f2",
    success: "#00c2a3",
    warning: "#ffba08",
    warningBg: "#ffe9af",
    warningLight: "#fff8e6",
    white: "#ffffff",
  },
  shadows: {
    center: "box-shadow: 0 0 8px 0 rgba(221, 219, 224, 0.6) !important;",
    centerUpper: "box-shadow: 0 0 8px 0 rgba(36, 31, 46, 0.6) !important;",
    down: "box-shadow: 0 4px 8px 0 rgba(221, 219, 224, 0.6) !important;",
    up: "box-shadow: 0 -4px 8px 0 rgba(221, 219, 224, 0.6) !important;",
    right: "box-shadow: 4px 0 8px 4px rgba(36, 31, 46, 0.4) !important;",
    blur: "backdrop-filter: blur(1px) !important; background-color: rgba(36, 31, 46, 0.3) !important;",
  },
  contentMaxWidth: 944,
  sidemenuWidth: {
    desktop: 236,
    mobileTablet: 285,
  },
  breakpoints: {
    mobile: 576,
    tablet: 768,
  },
};

export const theme = {
  ...DEFAULT_THEME,
};

const GlobalStyle = createGlobalStyle`
  html{
    height: 100%;
    font-size: 16px;
    --antd-wave-shadow-color: transparent;
  }
  body{
    margin: 0;
    padding: 0;
    min-height: 100%;
    letter-spacing: normal;
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    overflow-y: overlay;
  }
  #root{
    margin: 0;
    min-height: 100vh;
    width: 100%;
    display: flex;
    background-color: ${theme.palette.grey.bg};
    @media screen and (max-width: ${theme.breakpoints.tablet}px) {
      background-color: ${theme.palette.white};
    }
  }
  .ant-modal-mask{
    ${theme.shadows.blur}
  }
  ::selection{
    background: ${theme.palette.accent};
  }
`;

export const StyleProvider = ({ children }) => {
  const scrollbarWidth = useScrollbarWidth();
  const { mobile, tablet } = theme.breakpoints;
  const isMobile = useMedia(`only screen and (max-width: ${mobile}px)`);
  const isTablet = useMedia(`only screen and (max-width: ${tablet}px)`);
  const isDesktop = !isMobile && !isTablet;
  const [sidemenuOpen, setSidemenuOpen] = useState(() => isDesktop);
  const value = {
    theme,
    isMobile,
    isTablet,
    isDesktop,
    sidemenuOpen,
    setSidemenuOpen,
    scrollbarWidth,
  };
  return (
    <StyleContext.Provider value={value}>
      <ThemeProvider theme={theme}>
        {children}
        <GlobalStyle />
      </ThemeProvider>
    </StyleContext.Provider>
  );
};

StyleProvider.propTypes = {
  children: PropTypes.node,
};

StyleProvider.defaultProps = {
  children: null,
};

const useStyleContext = () => useContext(StyleContext);

export default useStyleContext;
