import React, {
  createContext,
  useContext,
  useEffect,
  useCallback,
  useState,
  useRef,
} from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import useAuthContext from "./Auth";
import useFeedbackContext from "./Feedback";
import locales from "../locales";

const APIContext = createContext();

export const APIProvider = ({ children }) => {
  const intl = useIntl();
  const [isOffline, setIsOffline] = useState(false);
  const throttleOfflineFeedback = useRef(true);
  const { requestAPIMethods, getAuthToken, logout } = useAuthContext();
  const { displayError } = useFeedbackContext();

  const request = useCallback(
    (method) => {
      if (isOffline) {
        return () => {
          if (throttleOfflineFeedback.current) {
            throttleOfflineFeedback.current = false;
            displayError(intl.formatMessage(locales.offline));
            setTimeout(() => {
              throttleOfflineFeedback.current = true;
            }, 2000);
          }
          return false;
        };
      }
      return async (
        path,
        payload = false,
        requiresAuth = true,
        plainBody = false,
        avoidStatusCodeErrorDisplay = false
      ) => {
        let url = window.env.API_HOST + path;
        const headers = {};
        const token = requiresAuth && getAuthToken();
        if (requiresAuth && !token) {
          displayError(intl.formatMessage(locales.session_expired));
          return undefined;
        }
        if (method !== "GET" && !plainBody) {
          headers["Content-Type"] = "application/json";
        }
        if (token) {
          headers.Authorization = `Bearer ${token}`;
        }
        const params = { method, headers };
        if (payload) {
          if (method === "GET") {
            url += `?${new window.URLSearchParams(payload).toString()}`;
          } else {
            params.body = plainBody ? payload : JSON.stringify(payload);
          }
        }

        try {
          const response = await window.fetch(url, params);
          const statusCode = parseInt(response.status, 10);
          if (requiresAuth && statusCode === 401) {
            logout();
            return false;
          }
          const jsonResponse = await response.json();
          if (!response.ok) {
            throw Error(JSON.stringify({ ...jsonResponse, statusCode }));
          }
          return jsonResponse;
        } catch (e) {
          try {
            const { statusCode, error, errors } = JSON.parse(e.message);
            if (statusCode === 422) {
              return { errors };
            }
            if (statusCode !== avoidStatusCodeErrorDisplay) {
              let errorMessage = intl.formatMessage(locales.ooops);
              if (error) {
                errorMessage = error;
              } else if (errors) {
                errorMessage = Object.values(errors)[0];
              }
              displayError(errorMessage);
            }
          } catch (eJsonErrorNotParseable) {
            displayError(intl.formatMessage(locales.ooops));
          }
          return false;
        }
      };
    },
    [getAuthToken, logout, displayError, intl, isOffline]
  );

  const apiMethods = {
    get: request("GET"),
    post: request("POST"),
    patch: request("PATCH"),
    del: request("DELETE"),
  };

  useEffect(
    () => requestAPIMethods(apiMethods),
    [apiMethods, requestAPIMethods]
  );
  useEffect(() => {
    window.addEventListener("online", () => setIsOffline(false));
    window.addEventListener("offline", () => setIsOffline(true));
  }, []);

  return (
    <APIContext.Provider value={apiMethods}>{children}</APIContext.Provider>
  );
};

APIProvider.propTypes = {
  children: PropTypes.node,
};

APIProvider.defaultProps = {
  children: null,
};

const useAPIContext = () => useContext(APIContext);

export default useAPIContext;
