import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useWindowScroll } from "react-use";
import { Layout } from "antd";
import PropTypes from "prop-types";
import UserIcon from "@material-ui/icons/PermIdentityRounded";
import SettingsIcon from "@material-ui/icons/Settings";
import CatalogueIcon from "./CustomIcons/images-icons-catalogue";
import SeQuraLogo from "./CustomIcons/images-sequra-sequra";
import Text from "./Text";
import Modal from "./Modal";
import useStyleContext from "../providers/Style";

const SCROLL_SHADOW_THRESOLD = 40;

const StyledHeader = styled(Layout.Header)`
  width: 100vw;
  background-color: ${({ theme }) => theme.palette.white};
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  display: flex;
  justify-content: ${({ $onlyLogo }) => ($onlyLogo ? "center" : "flex-end")};
  align-items: center;
  height: 56px;
  padding: 16px;
  user-select: none;
  .logo {
    width: 125px;
    height: 28px;
    flex-shrink: 0;
  }
  ${({ theme, $isDesktop, $showMobileShadow }) =>
    $isDesktop || $showMobileShadow ? theme.shadows.down : ""}
  & > div {
    display: ${({ $isDesktop, $onlyLogo }) =>
      $isDesktop || $onlyLogo ? "flex" : "none"};
    cursor: pointer;
    ${({ $onlyLogo }) => ($onlyLogo ? "" : "margin-left: 16px;")};
    &:first-child {
      cursor: default;
      justify-content: center;
      ${({ $isDesktop, $onlyLogo }) =>
        $isDesktop || $onlyLogo ? "" : "display: none;"};
      ${({ $onlyLogo }) => ($onlyLogo ? "" : "margin-right: auto;")};
      & > .logo {
        cursor: pointer;
        margin: ${({ $onlyLogo }) =>
          $onlyLogo ? "0 auto" : "-8px 36px 0 -4px"};
        height: 0;
        & > svg {
          height: 20px;
        }
      }
      & > .ant-typography {
        max-width: 40vw;
      }
    }
    & > span {
      display: flex;
      & > svg g path:last-child {
        fill: ${({ theme }) => theme.palette.grey.primary};
      }
    }
  }
  & > .ant-typography {
    ${({ $isDesktop }) => ($isDesktop ? "width: auto;" : "")}
    margin: 0 16px 0 32px !important;
  }
  & > svg {
    ${({ $isDesktop }) => ($isDesktop ? "" : "cursor: pointer;")}
  }
`;

const StyledMobileModal = styled(Modal)`
  & .ant-modal-body {
    padding: 0;
    & > h1 {
      margin: 16px 24px !important;
    }
    & > div {
      display: flex;
      height: 40px;
      align-items: center;
      &.logo > svg {
        margin: 24px 0 0 24px;
        cursor: pointer;
      }
      &.user {
        background-color: ${({ theme }) => theme.palette.grey.bg};
        width: calc(100% - 48px);
        margin: 0 auto 16px auto;
        padding: 0 8px;
      }
      &:last-child {
        flex-direction: column;
        height: auto;
        border-top: 1px solid ${({ theme }) => theme.palette.grey.shade};
        padding: 8px 0;
        & > div {
          height: 40px;
        }
        & g path {
          fill: transparent;
          &:last-child {
            fill: ${({ theme }) => theme.palette.grey.secondary};
          }
        }
      }
      & > div {
        cursor: pointer;
        padding: 24px;
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        & > svg {
          margin: 0 11px 0 8px;
          & g path:last-child {
            fill: ${({ theme }) => theme.palette.grey.secondary};
          }
        }
      }
      & svg {
        margin-right: 8px;
        & path {
          fill: ${({ theme }) => theme.palette.grey.secondary};
        }
      }
    }
  }
`;

const Header = ({
  onlyLogo,
  title,
  merchantName,
  userName,
  catalogue,
  settings,
  navigateIndex,
}) => {
  const [showMobileIcons, setShowMobileIcons] = useState(false);
  const { y: scrollVerticalPosition } = useWindowScroll();
  const { isDesktop } = useStyleContext();
  let content;

  useEffect(() => {
    if (isDesktop && showMobileIcons) {
      setShowMobileIcons(false);
    }
  }, [isDesktop, showMobileIcons, setShowMobileIcons]);

  const onClickLogo = () => {
    setShowMobileIcons(false);
    if (navigateIndex !== null) {
      navigateIndex();
    }
  };

  const logo = (
    <div className="logo" onClick={onClickLogo}>
      <SeQuraLogo />
    </div>
  );

  if (onlyLogo) {
    content = logo;
  } else {
    content = (
      <>
        <div>
          {logo}
          <Text heading1 ellipsis>
            {merchantName}
          </Text>
        </div>
        {isDesktop ? null : (
          <Text cta_s center capitalize>
            {title}
          </Text>
        )}

        {isDesktop ? (
          <Text right data-hj-suppress>
            {userName}
          </Text>
        ) : (
          <UserIcon onClick={() => setShowMobileIcons(true)} />
        )}
      </>
    );
  }
  const onClickMobileNav =
    isDesktop || onlyLogo
      ? null
      : (entry) => () => {
          setShowMobileIcons(false);
          entry.nav();
        };

  const mobileModal =
    typeof onClickMobileNav !== "function" ? null : (
      <StyledMobileModal
        visible={showMobileIcons}
        onCancel={() => setShowMobileIcons(false)}
      >
        {logo}
        <Text heading1 ellipsis>
          {merchantName}
        </Text>
        <div className="user">
          <UserIcon />
          <Text secondary data-hj-suppress>
            {userName}
          </Text>
        </div>
        <div>
          {catalogue ? (
            <div onClick={onClickMobileNav(catalogue)}>
              <CatalogueIcon />
              <Text secondary>{catalogue.text}</Text>
            </div>
          ) : null}
          {settings ? (
            <div onClick={onClickMobileNav(settings)}>
              <SettingsIcon />
              <Text secondary>{settings.text}</Text>
            </div>
          ) : null}
        </div>
      </StyledMobileModal>
    );

  return (
    <StyledHeader
      $isDesktop={isDesktop}
      $onlyLogo={onlyLogo}
      $showMobileShadow={scrollVerticalPosition > SCROLL_SHADOW_THRESOLD}
      data-testid="header"
    >
      {content}
      {mobileModal}
    </StyledHeader>
  );
};

Header.propTypes = {
  onlyLogo: PropTypes.bool,
  title: PropTypes.string,
  merchantName: PropTypes.string,
  userName: PropTypes.string,
  catalogue: PropTypes.object,
  settings: PropTypes.object,
  navigateIndex: PropTypes.func,
};

Header.defaultProps = {
  onlyLogo: false,
  title: null,
  merchantName: null,
  userName: null,
  catalogue: null,
  settings: null,
  navigateIndex: null,
};

export default Header;
