import React, { useState, useRef, forwardRef } from "react";
import { Input } from "antd";
import styled from "styled-components";
import PropTypes from "prop-types";
import useStyleContext from "../providers/Style";

const DEBOUNCE_TIMEOUT = 500;

const StyledInput = styled(Input)`
  height: 36px;
  font-size: 18px;
  border-radius: 0;
  border-bottom: 1px solid
    ${({ theme, disabled }) =>
      theme.palette.grey[disabled ? "muted" : "secondary"]} !important;
  padding: 0;
  caret-color: ${({ theme }) => theme.palette.accent};
  ${({ theme, disabled }) =>
    disabled ? `color: ${theme.palette.grey.muted} !important;` : ""}
  &:hover,
  &:focus {
    border-bottom: 1px solid
      ${({ theme, disabled }) =>
        theme.palette.grey[disabled ? "muted" : "secondary"]} !important;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: 0 0 0 30px ${({ theme }) => theme.palette.white} inset !important;
  }
  &:-webkit-autofill::first-line {
    letter-spacing: normal;
    font-family: "Open Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 18px;
  }
  &:-internal-autofill-selected {
    font-family: inherit;
    background: transparent !important;
  }
  & .ant-input-prefix,
  & .ant-input-suffix {
    & svg {
      margin-bottom: 2px;
      path {
        fill: ${({ theme }) => theme.palette.grey.muted};
      }
    }
  }
  & .ant-input-suffix {
    margin-left: -2px;
  }
`;

const TextInput = forwardRef((props, ref) => {
  const {
    password,
    numericMobile,
    onFocus,
    onBlur,
    onChange,
    onClick,
    placeholder,
    debounce,
    forcePlaceholder,
    ...otherProps
  } = props;
  const [{ focus, readOnly }, setState] = useState({
    focus: false,
    readOnly: true,
  });
  const debounceTimeoutRef = useRef();
  const { isTablet } = useStyleContext();
  const numericInputMode =
    numericMobile && isTablet ? { inputMode: "numeric" } : {};

  const handleOnFocus = (...args) => {
    setState({ focus: true, readOnly: false });
    if (onFocus) {
      onFocus(...args);
    }
  };
  const handleOnBlur = (...args) => {
    setState({ focus: false, readOnly: true });
    if (onBlur) {
      onBlur(...args);
    }
  };
  const handleOnChange = (...args) => {
    if (debounce) {
      window.clearTimeout(debounceTimeoutRef.current);
      debounceTimeoutRef.current = window.setTimeout(
        () => onChange(...args),
        DEBOUNCE_TIMEOUT
      );
    } else {
      onChange(...args);
    }
  };
  const handleOnClick = (...args) => {
    if (onClick) {
      onClick(...args);
    }
  };

  return (
    <StyledInput
      ref={ref}
      autoComplete={password ? "new-password" : "off"}
      type={password ? "password" : "text"}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      onTouchStart={handleOnFocus}
      onChange={handleOnChange}
      onClick={handleOnClick}
      placeholder={forcePlaceholder || focus ? placeholder : ""}
      readOnly={readOnly}
      size="large"
      bordered={false}
      spellCheck={false}
      autoCorrect="off"
      role="textbox"
      data-testid="textInput"
      {...numericInputMode}
      {...otherProps}
    />
  );
});

TextInput.propTypes = {
  password: PropTypes.bool,
  numericMobile: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  debounce: PropTypes.bool,
  forcePlaceholder: PropTypes.bool,
};

TextInput.defaultProps = {
  password: false,
  numericMobile: false,
  onFocus: null,
  onBlur: null,
  onChange: () => null,
  onClick: () => null,
  placeholder: "",
  debounce: false,
  forcePlaceholder: false,
};

export default TextInput;
