import React from "react";
import useAuthContext from "./Auth";
import { PageLayout, Header } from "../ui";
import ShopperCart from "../pages/ShopperCart";

const withoutRouter = (page, showHeader = true) => (
  <PageLayout.Container key="container">
    {showHeader ? <Header onlyLogo /> : null}
    {page}
  </PageLayout.Container>
);

const Router = () => {
  const { shopperCartCode } = useAuthContext();

  if (shopperCartCode) {
    return withoutRouter(<ShopperCart key="shopper_cart" />);
  }
  return null;
};

export default Router;
