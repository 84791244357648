import React, { createContext, useContext, useEffect } from "react";
import { useIntl } from "react-intl";
import { message } from "antd";
import * as Sentry from "@sentry/react";
import mixpanel from "mixpanel-browser";
import { hotjar } from "react-hotjar";
import PropTypes from "prop-types";
import { isLocalhost } from "../utils";
import useAuthContext from "./Auth";
import { FeedbackSnack } from "../ui";
import locales from "../locales";

const ERROR_WHITELIST = [
  "ResizeObserver loop completed with undelivered notifications",
  "ResizeObserver loop limit exceeded",
];

const isWhitelistedError = (error) =>
  ERROR_WHITELIST.some((whitelistedError) =>
    whitelistedError.includes(error.message)
  );

if (!isLocalhost) {
  mixpanel.init(window.env.MIXPANEL_API_KEY, { track_pageview: false });

  hotjar.initialize(window.env.HOTJAR_API_KEY, window.env.HOTJAR_API_VERSION);
}

const FeedbackContext = createContext();

export const FeedbackProvider = ({ children }) => {
  const intl = useIntl();
  const { merchantReference, userInfo, storeReference } = useAuthContext();
  const { id } = userInfo || {};

  const displayMessage = (type, text) => {
    const key = Math.random().toString();
    const content = (
      <FeedbackSnack type={type} onClose={() => message.destroy(key)}>
        {text}
      </FeedbackSnack>
    );
    message.open({ key, content });
  };

  const trackUserEvent = (action, payload = {}) => {
    if (!isLocalhost) {
      hotjar.event(action);
      mixpanel.track(action, {
        merchantReference,
        storeReference,
        ...payload,
      });
    }
  };

  const value = {
    trackUserEvent,
    displayError: (text) => displayMessage("error", text),
    displaySuccess: (text) => displayMessage("success", text),
    displayWarning: (text) => displayMessage("warning", text),
    reportToSentry: (error, context = {}) =>
      Sentry.withScope((scope) => {
        scope.setTags(context.tags);
        scope.setExtras(context.extra);
        Sentry.captureException(error);
      }),
  };

  useEffect(() => {
    const handle = (error) => {
      if (!isWhitelistedError(error)) {
        value.displayError(intl.formatMessage(locales.ooops));
      }
    };
    window.addEventListener("error", handle);
    window.addEventListener("unhandledrejection", handle);
  }, [intl, value]);

  useEffect(() => {
    if (!isLocalhost) {
      if (id) {
        hotjar.identify(id);
        mixpanel.identify(id);
      } else {
        hotjar.identify(null);
        mixpanel.reset();
      }
    }
  }, [id]);

  return (
    <FeedbackContext.Provider value={value}>
      {children}
    </FeedbackContext.Provider>
  );
};

FeedbackProvider.propTypes = {
  children: PropTypes.node,
};

FeedbackProvider.defaultProps = {
  children: null,
};

const useFeedbackContext = () => useContext(FeedbackContext);

export default useFeedbackContext;
