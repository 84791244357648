import React from "react";
import styled from "styled-components";
import { Layout, Row, Col } from "antd";
import PropTypes from "prop-types";
import useStyleContext from "../providers/Style";

const StyledLayout = styled(Layout)`
  background-color: ${({ theme }) => theme.palette.grey.bg};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    background-color: ${({ theme }) => theme.palette.white};
  }
`;

const handleSideMenuMargin = ({
  theme,
  $isDesktop,
  $sidemenuOpen,
  $noSidemenu,
  $scrollbarWidth,
}) => {
  let sidemenuMargin = 0;
  if ($isDesktop && !$noSidemenu) {
    sidemenuMargin = 16 + ($sidemenuOpen ? theme.sidemenuWidth.desktop : 24);
  }
  const translateX = $noSidemenu
    ? false
    : Math.round(sidemenuMargin / 2) - ($isDesktop ? 8 : 0);
  return `
    width: calc(100vw - ${
      sidemenuMargin + ($scrollbarWidth || 0) + ($isDesktop ? 28 : 36)
    }px);
    ${translateX ? `transform: translateX(${translateX}px);` : ""}
  `;
};

const StyledContent = styled(Layout.Content)`
  ${({ theme, $noMaxWidth }) =>
    $noMaxWidth ? "" : `max-width: ${theme.contentMaxWidth}px;`}
  margin: 90px auto 0 auto;
  ${handleSideMenuMargin}
  background-color: ${({ theme }) => theme.palette.grey.bg};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    background-color: ${({ theme }) => theme.palette.white};
  }
  transition: all 0.1s linear;
  & > .ant-row {
    width: 100%;
    & > .ant-col {
      &:first-child {
        padding-left: 0 !important;
      }
      &:nth-child(${({ $numColumns }) => $numColumns}) {
        padding-right: 0 !important;
      }
    }
  }
`;

const Content = ({ children, className, noSidemenu, noMaxWidth }) => {
  const { isDesktop, sidemenuOpen, scrollbarWidth } = useStyleContext();
  const numColumns = React.Children.toArray(children).filter(
    ({ type }) => type.name === "Column"
  ).length;
  return (
    <StyledContent
      className={className}
      $numColumns={numColumns}
      $isDesktop={isDesktop}
      $sidemenuOpen={sidemenuOpen}
      $noSidemenu={noSidemenu}
      $scrollbarWidth={scrollbarWidth}
      $noMaxWidth={noMaxWidth}
    >
      <Row justify="center" gutter={numColumns ? 24 : 0}>
        {children}
      </Row>
    </StyledContent>
  );
};

Content.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  noSidemenu: PropTypes.bool,
  noMaxWidth: PropTypes.bool,
};

Content.defaultProps = {
  children: null,
  className: "",
  noSidemenu: false,
  noMaxWidth: false,
};

const Container = ({ children, ...props }) => {
  return <StyledLayout {...props}>{children}</StyledLayout>;
};

Container.propTypes = {
  children: PropTypes.node,
};

Container.defaultProps = {
  children: null,
};

const StyledCol = styled(Col)`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    &.notSingle {
      padding-left: 12px;
      padding-right: 12px;
      &:not(& ~ &)& {
        padding-left: 0;
      }
      & ~ & {
        padding-right: 0;
      }
    }
  }
`;

const Column = ({ children, desktop, className, ...props }) => {
  const mdProp = desktop ? { md: parseInt(desktop, 10) * 3 } : {};
  const classNames = className + (desktop !== null ? " notSingle" : "");
  return (
    <StyledCol className={classNames} span={24} {...mdProp} {...props}>
      {children}
    </StyledCol>
  );
};

Column.propTypes = {
  children: PropTypes.node,
  desktop: PropTypes.string,
  className: PropTypes.string,
};

Column.defaultProps = {
  children: null,
  desktop: null,
  className: "",
};

export default { Container, Content, Column };
