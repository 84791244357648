import React, { createContext, useContext, useState, useRef } from "react";
import { useMount, useSetState } from "react-use";
import PropTypes from "prop-types";
import useAuthContext from "./Auth";
import useAPIContext from "./API";
import useStyleContext from "./Style";
import useFeedbackContext from "./Feedback";
import { amountToCents } from "../utils";

const OrdersContext = createContext();

export const OrdersProvider = ({ children }) => {
  const { merchantReference, setMerchantReference } = useAuthContext();
  const { get, post } = useAPIContext();
  const { trackUserEvent } = useFeedbackContext();
  const [itemsCart, setItemsCart] = useSetState({
    items: [],
    discount: 0,
    service: null,
    personalData: null,
  });
  const [otherPayment, setOtherPayment] = useSetState({
    name: "",
    total_with_tax: 0,
  });
  const [installmentsCount, setInstallmentsCount] = useSetState(null);
  const [schema, setSchema] = useState(null);
  const [orders, setOrders] = useState({ data: null, pagination: null });
  const { isDesktop } = useStyleContext();
  const orderPollingInterval = useRef();

  useMount(() => {
    const fetchSchema = async () => {
      const merchantSchema = await get(
        `/api/v1/merchants/${merchantReference}`,
        false,
        false,
        false,
        404
      );
      if (merchantSchema) {
        setSchema(merchantSchema);
        setMerchantReference(merchantSchema.reference);
      } else {
        setMerchantReference(null);
      }
    };
    if (merchantReference) {
      fetchSchema();
    }
  });

  const fetchOrders = async (search, per_page, page) => {
    const payload = {};
    if (per_page) {
      payload.per_page = per_page;
    }
    if (page) {
      payload.page = page;
    }
    if (search) {
      payload.q = search;
    }
    const response = await get("/api/v1/orders", payload);
    if (response) {
      setOrders(response);
    }
  };

  const hasEditableCartItemPrice = !!schema?.configuration?.fields.find(
    ({ name }) => name === "cart_items"
  )?.editable;

  const createOrder = async (
    values,
    discount,
    other_payment,
    _installmentsCount = null,
    newFlow = false
  ) => {
    const payload = Object.keys(values).reduce(
      (prev, key) => {
        let value = values[key].toString();
        const schemaField = schema.configuration.fields.find(
          ({ name }) => name === key
        );
        if (!schemaField) {
          return prev;
        }
        if (schemaField.type === "decimal") {
          value = amountToCents(value);
        }
        if (schemaField.type === "enum") {
          value = {
            code: value,
            name: schemaField.values.find((v) => v.key === value).name,
          };
        }
        if (
          [
            "inventory_item",
            "multi_item_inventory",
            "single_service_inventory",
          ].includes(schemaField.type)
        ) {
          value = values[key];
        }
        return { ...prev, [key]: value };
      },
      discount ? { discount } : {}
    );

    if (other_payment?.total_with_tax > 0) {
      payload.other_payment = other_payment;
    }

    if (_installmentsCount) {
      payload.installments_count = Object.keys(_installmentsCount).map(
        (product) => ({
          product,
          values: Object.entries(_installmentsCount[product]).reduce(
            (acc, el) => {
              const [k, v] = el;
              if (!v) {
                return acc;
              }
              return [...acc, parseInt(k, 10)];
            },
            []
          ),
        })
      );
    }
    const order = await post("/api/v1/orders", payload);
    if (order?.errors) {
      throw order.errors;
    }
    if (!order) {
      return false;
    }
    setOrders({ data: [order], pagination: null });
    trackUserEvent("Create order", { orderId: order.id, newFlow });
    return order.id;
  };

  const getOrder = (id) => {
    return orders.data?.find((order) => order.id === id);
  };

  const resendSMS = async (id) => {
    await post(`/api/v1/orders/${id}/resend_sms`, {
      mobile_phone: getOrder(id).sms_mobile_phone,
    });
  };

  const resendOrder = async (id) => {
    await post(`/api/v1/orders/${id}/reset`, {
      mobile_phone: getOrder(id).sms_mobile_phone,
    });
  };

  const cancelOrder = async (id) => {
    const cancelledOrder = await post(`/api/v1/orders/${id}/cancel`);
    const { data, pagination } = orders;
    const updatedData = data.map((order) => {
      if (order.id === cancelledOrder.id) {
        return cancelledOrder;
      }
      return order;
    });
    setOrders({ data: updatedData, pagination });
  };

  let canCancelOrder = schema?.configuration?.has_cancellation_enabled;
  if (typeof canCancelOrder !== "boolean") {
    canCancelOrder = true;
  }

  const stopOrderPolling = () => {
    window.clearInterval(orderPollingInterval.current);
  };

  const startOrderPolling = (orderId) => {
    const fetchSingleOrder = async () => {
      const order = await get(`/api/v1/orders/${orderId}`);
      setOrders({ data: [order], pagination: null });
    };
    stopOrderPolling();
    fetchSingleOrder();
    orderPollingInterval.current = window.setInterval(
      fetchSingleOrder,
      window.env.POLLING_INTERVAL * 1000
    );
  };

  const addItemToCart = (item) =>
    setItemsCart({ items: [...itemsCart.items, { ...item, quantity: 1 }] });

  const updateCartItemQuantity = (itemId, quantity) => {
    let items = [...itemsCart.items];
    if (quantity > 0) {
      items = items.map((item) => {
        if (itemId !== item.id) {
          return item;
        }
        return { ...item, quantity };
      });
    } else {
      items = items.filter((item) => item.id !== itemId);
    }
    setItemsCart({
      items,
      discount: items.length ? itemsCart.discount : 0,
    });
    setOtherPayment({
      name: items.length ? otherPayment.name : "",
      total_with_tax: items.length ? otherPayment.total_with_tax : 0,
    });
  };

  const updateCartItemPrice = (itemId, price) => {
    const items = itemsCart.items.map((item) => {
      if (itemId !== item.id) {
        return item;
      }
      return {
        ...item,
        price_with_tax: { string: `${price} €`, value: amountToCents(price) },
      };
    });
    setItemsCart({ items });
  };

  const newCreateOrder = async () => {
    const { items, service, discount, personalData } = itemsCart;
    const cart_items = items.map((item) => ({
      ...item,
      price_with_tax: item.price_with_tax.value,
    }));
    const cart_services = [];
    if (service?.id) {
      cart_services.push({
        ...service,
        quantity: 1,
        price_with_tax: service.price_with_tax.value,
      });
    }
    const values = Object.keys(personalData).reduce(
      (prev, key) => ({
        ...prev,
        [key]: personalData[key] || "",
      }),
      { cart_items, cart_services }
    );

    const orderId = await createOrder(
      values,
      discount,
      otherPayment,
      installmentsCount,
      true
    );
    if (orderId) {
      setTimeout(() => {
        setItemsCart({
          items: [],
          discount: 0,
          service: null,
          personalData: null,
        });
        setOtherPayment({
          name: "",
          total_with_tax: 0,
        });
      }, 500);
    }
    return orderId;
  };

  const forceOldOrderFlow =
    schema?.configuration?.merchant_type === "workshop" ||
    schema?.configuration?.merchant_type === "workshop_multistore" ||
    (!isDesktop && schema?.configuration?.merchant_type === "retail");

  const withInstallmentsCustomization =
    schema?.configuration?.has_installments_customization;

  const hasOtherPaymentEnabled =
    schema?.configuration?.has_other_payment_enabled;

  const value = {
    schema,
    createOrder,
    newCreateOrder,
    fetchOrders,
    resendSMS,
    resendOrder,
    cancelOrder,
    canCancelOrder,
    startOrderPolling,
    stopOrderPolling,
    getOrder,
    itemsCart,
    otherPayment,
    addItemToCart,
    updateCartItemQuantity,
    updateCartItemPrice,
    hasEditableCartItemPrice,
    hasOtherPaymentEnabled,
    updateCartService: (service) => setItemsCart({ service }),
    updateCartDiscount: (discount) => setItemsCart({ discount }),
    updateCartOtherPayment: (newOtherPayment) => {
      setOtherPayment(newOtherPayment);
    },
    updateCartPersonalData: (personalData) => setItemsCart({ personalData }),
    withInstallmentsCustomization,
    installmentsCount,
    updateInstallmentsCount: setInstallmentsCount,
    orders: orders.data,
    pagination: orders.pagination,
    newCreateOrderWizard: !forceOldOrderFlow,
    clearOrders: () => setOrders({ data: null, pagination: null }),
  };
  return (
    <OrdersContext.Provider value={value}>{children}</OrdersContext.Provider>
  );
};

OrdersProvider.propTypes = {
  children: PropTypes.node,
};

OrdersProvider.defaultProps = {
  children: null,
};

const useOrdersContext = () => useContext(OrdersContext);

export default useOrdersContext;
