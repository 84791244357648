/* eslint-disable import/no-unresolved */
/* eslint-disable func-names */
import esES from "./compiled-lang/es-ES.json";
import frFR from "./compiled-lang/fr-FR.json";
import itIT from "./compiled-lang/it-IT.json";
import ptPT from "./compiled-lang/pt-PT.json";

export default function (locale) {
  switch (locale) {
    case "fr-FR":
      return frFR;
    case "pt-PT":
      return ptPT;
    case "it-IT":
      return itIT;
    default:
      return esES;
  }
}
