import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { supportedLocales } from "../utils";

const disclaimer = {
  name: "SVEA",
  message: "disclaimer_for_svea",
  logo: "https://live.sequracdn.com/assets/images/logos/svea_primary_rgb_small.png",
};

const DisclaimerContent = styled.div`
  color: ${({ theme }) => theme.palette.grey.secondary};
  font-size: 12px;
  img {
    width: 24px;
    height: auto;
  }
`;

const Disclaimer = ({ intl, locales }) => {
  const disclaimerMessage = intl.formatMessage(locales[disclaimer.message], {
    logo: <img alt={disclaimer.name} src={disclaimer.logo} />,
  });

  if (window.locale === supportedLocales.es) {
    return null;
  }

  return <DisclaimerContent>{disclaimerMessage}</DisclaimerContent>;
};

Disclaimer.propTypes = {
  intl: PropTypes.object.isRequired,
  locales: PropTypes.object.isRequired,
};

export default Disclaimer;
