import React from "react";
import { createGlobalStyle } from "styled-components";
import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/CloseRounded";
import SuccessIcon from "@material-ui/icons/DoneAllRounded";
import CancelIcon from "@material-ui/icons/CancelRounded";
import WarningIcon from "@material-ui/icons//WarningRounded";
import { theme } from "../providers/Style";

const GlobalFeedbackSnackStyle = createGlobalStyle`
  .ant-message-notice-content{
    background: transparent;
    padding: 0;
    border-radius: 8px;
    & .feedbackSnack{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 16px;
      border-radius: 8px;
      height: 56px;
      &.success{
        background-color: ${theme.palette.accent};
      }
      &.error{
        background-color: ${theme.palette.error};
      }
      &.warning{
        background-color: ${theme.palette.warning};
      }
      & > div {
        cursor: pointer;
        margin-left: auto;
        display: flex;
      }
      & > span,
      & > svg {
        margin-right: 16px;
      }
      & svg path {
        fill: ${theme.palette.white};
      }
      & > span{
        font-size: 16px;
        color: ${theme.palette.white};
      }
    }
  }
`;

const renderIcon = (type) => {
  switch (type) {
    case "success":
      return <SuccessIcon />;
    case "error":
      return <CancelIcon />;
    case "warning":
      return <WarningIcon />;
    default:
      return null;
  }
};

const FeedbackSnack = ({ type, children, onClose }) => (
  <>
    <div className={`feedbackSnack ${type}`}>
      {renderIcon(type)}
      <span>{children}</span>
      <div onClick={onClose}>
        <CloseIcon />
      </div>
    </div>
    <GlobalFeedbackSnackStyle />
  </>
);

FeedbackSnack.propTypes = {
  type: PropTypes.string,
  children: PropTypes.string,
  onClose: PropTypes.func,
};

FeedbackSnack.defaultProps = {
  type: "error",
  children: "",
  onClose: () => null,
};

export default FeedbackSnack;
