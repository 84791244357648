import React, { useState, useRef } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import DeleteIcon from "@material-ui/icons/DeleteOutlineRounded";
import PlusIcon from "@material-ui/icons/AddRounded";
import MinusIcon from "@material-ui/icons/RemoveRounded";
import EditIcon from "@material-ui/icons/Edit";
import { amountToCents, centsToAmount, isAmountString } from "../utils";
import Text from "./Text";
import TextInput from "./TextInput";
import CartItemImage from "./CartItemImage";

const Wrap = styled.div`
  display: flex;
  width: 100%;
  & > div {
    display: flex;
    flex-direction: column;
    min-height: 72px;
    width: calc(100% - 88px);
    ${({ $noQuantity }) => ($noQuantity ? "" : "margin-bottom: 24px;")}
    & > .ant-typography:first-child {
      margin-bottom: 8px !important;
      display: -webkit-box;
    }
    &:first-child {
      margin-right: 16px;
      width: ${({ $withImage }) => ($withImage ? "72px" : "100%")};
    }
  }
  .highlight {
    color: ${({ theme }) => theme.palette.primary} !important;
    background-color: transparent;
  }
  .shopperQuantity {
    display: inline-flex;
    white-space: pre;
  }

  .Item {
    display: flex;
    &_Control {
      display: flex;
      user-select: none;
      margin-top: 5px;
      margin-right: 10px;
    }
    &_Button {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 40px;
      width: 40px;
      border-radius: 8px;
      background-color: ${({ theme }) => theme.palette.actionBg};
      cursor: pointer;
      &__Remove {
        ${({ theme, $singleItem }) =>
          $singleItem &&
          `background-color: ${theme.palette.grey.shade};
          svg {
            fill: ${theme.palette.grey.primary};
          }
        `}
      }
    }
    &_Quantity {
      background-color: transparent;
      cursor: default;
      text-align: center;
      width: 48px;
      ${({ $noQuantity }) => ($noQuantity ? "" : "margin-top: 8px;")}
    }
    &_price {
      max-width: 50%;
      margin: 8px 0 0 auto;
      &.ant-input {
        margin-top: 0 !important;
        ${({ theme, $isZeroPrice }) =>
          $isZeroPrice && `border-color: ${theme.palette.error} !important;`}
        &:focus,
        &:hover {
          border-color: ${({ theme }) => theme.palette.grey.muted};
          box-shadow: none;
        }
      }
    }
  }
`;

const CartItem = ({
  item,
  updateQuantity,
  updatePrice,
  remove,
  highlight,
  className,
  shopper,
  service,
  withImage,
}) => {
  const { name, image_urls, price_with_tax, quantity, reference } = item;
  const [price, setPrice] = useState(centsToAmount(price_with_tax));
  const originalPriceWithTax = useRef(
    updatePrice !== null && centsToAmount(price_with_tax)
  );
  const [imageUrl] = image_urls || [];
  const singleItem = quantity === 1 || service;
  const noQuantity = singleItem ? remove === null : updateQuantity === null;
  const onChangePrice =
    updatePrice !== null
      ? (e) => {
          const value = e.target.value || "0";
          if (isAmountString(value)) {
            updatePrice(value);
            setPrice(value === "0" ? "" : value);
          }
        }
      : null;
  const showImage = withImage && !service;

  return (
    <Wrap
      data-testid={noQuantity ? "" : "cartitem"}
      $singleItem={singleItem}
      $noQuantity={noQuantity}
      $withImage={showImage}
      $isZeroPrice={!amountToCents(price)}
      className={className}
    >
      {showImage ? <CartItemImage image={imageUrl} /> : null}
      <div>
        <Text ellipsis="2" muted={noQuantity} highlight={highlight}>
          {name}
        </Text>
        <div>
          {shopper ? (
            <div className="shopperQuantity">
              <Text>{shopper.quantity}</Text>
              <Text secondary>{` ${shopper.text}`}</Text>
            </div>
          ) : (
            <Text body2 secondary muted={noQuantity} highlight={highlight}>
              {reference}
            </Text>
          )}
          {noQuantity ? <Text right>{` ${price} €`}</Text> : null}
        </div>
        {noQuantity ? null : (
          <div className="Item">
            <div className="Item_Control">
              <div
                className="Item_Button Item_Button__Remove"
                data-testid="cartitem-remove"
                onClick={() =>
                  singleItem ? remove() : updateQuantity(quantity - 1)
                }
              >
                {singleItem ? <DeleteIcon /> : <MinusIcon />}
              </div>
              {service ? null : (
                <div className="Item_Quantity">
                  <Text cta_l center>
                    {quantity}
                  </Text>
                </div>
              )}
              {service ? null : (
                <div
                  className="Item_Button Item_Button__Add"
                  data-testid="cartitem-add"
                  onClick={() => updateQuantity(quantity + 1)}
                >
                  <PlusIcon />
                </div>
              )}
            </div>
            {onChangePrice === null ? (
              <Text
                className="price"
                right
                highlight={highlight}
              >{`${price} €`}</Text>
            ) : (
              <TextInput
                className="Item_price"
                data-testid="cartitem-editprice-input"
                onChange={onChangePrice}
                onFocus={({ target }) => setTimeout(() => target.select(), 0)}
                onClick={({ target }) => target.select()}
                value={price}
                suffix={<EditIcon />}
                numericMobile
                placeholder={originalPriceWithTax.current}
              />
            )}
          </div>
        )}
      </div>
    </Wrap>
  );
};

CartItem.propTypes = {
  item: PropTypes.object,
  updateQuantity: PropTypes.func,
  updatePrice: PropTypes.func,
  remove: PropTypes.func,
  highlight: PropTypes.string,
  className: PropTypes.string,
  shopper: PropTypes.shape({
    quantity: PropTypes.number,
    text: PropTypes.string,
  }),
  service: PropTypes.bool,
  withImage: PropTypes.bool,
};

CartItem.defaultProps = {
  item: {},
  updateQuantity: null,
  updatePrice: null,
  remove: null,
  highlight: "",
  className: "",
  shopper: null,
  service: false,
  withImage: true,
};

export default CartItem;
