import React, { createContext, useContext, useEffect, useState } from "react";
import { useMount } from "react-use";
import PropTypes from "prop-types";
import useWidgetSimulatorContext from "./WidgetSimulator";
import useAPIContext from "./API";
import useAuthContext from "./Auth";
import useFeedbackContext from "./Feedback";
import { centsToAmount, prefetchImage, supportedLocales } from "../utils";

const ShopperCartContext = createContext();

export const ShopperCartProvider = ({ children, handleChangeLocale }) => {
  const [cartData, setCartData] = useState(null);
  const [renderData, setRenderData] = useState(null);
  const { computeCreditAgreements, loadWidget } = useWidgetSimulatorContext();
  const { get, post } = useAPIContext();
  const { shopperCartCode } = useAuthContext();
  const { trackUserEvent } = useFeedbackContext();

  useMount(() => {
    if (shopperCartCode) {
      const fetchCartItems = async () => {
        const response = await get(
          `/api/v1/carts/${shopperCartCode}`,
          false,
          false
        );
        if (response) {
          const {
            items,
            services,
            total_with_tax,
            merchant_info,
            discount,
            other_payment,
            cart_handling,
            cart_registration,
            setup_fee,
            id,
            installments_count,
            status,
          } = response;
          const {
            merchant,
            merchantStore,
            assetKey,
            products,
            hasCatalogImages,
            countryCode,
          } = merchant_info;

          if (supportedLocales[countryCode.toLowerCase()] !== window.locale) {
            handleChangeLocale(supportedLocales[countryCode.toLowerCase()]);
          }

          const checkoutRedirect = async (product_code) => {
            const postResponse = await post(
              "/api/v1/solicitations",
              {
                product_code,
                persisted_cart_code: shopperCartCode,
              },
              false
            );
            if (postResponse) {
              const { checkout_url, order_uuid } = postResponse;
              await trackUserEvent("Select Payment Method", {
                orderId: id,
                orderUuid: order_uuid,
                productCode: product_code,
                merchantReference: merchant,
                storeReference: merchantStore,
              });
              window.location.assign(checkout_url);
            }
          };

          const cartItems = (items || []).map((item) => ({
            id: item.id,
            image_urls: item.image_urls,
            name: item.name,
            quantity: item.quantity,
            price_with_tax: item.total_with_tax.value,
          }));
          const cartServices = (services || []).map((service) => ({
            id: service.id,
            image_urls: service.image_urls,
            name: service.name,
            quantity: service.quantity,
            price_with_tax: service.total_with_tax.value,
          }));

          const cartSubtotal = centsToAmount(
            total_with_tax.value +
              (discount?.value ?? 0) +
              (other_payment?.total_with_tax.value ?? 0) -
              (cart_handling?.total_with_tax.value ?? 0) -
              (cart_registration?.total_with_tax.value ?? 0)
          );

          const cartSummary = {
            quantity: [...cartServices, ...cartItems].reduce(
              (prev, item) => prev + item.quantity,
              0
            ),
            subtotal: `${cartSubtotal} €`,
            discount: discount.value ? discount.string : null,
            otherPayment: {
              name: other_payment?.name ?? "",
              total_with_tax: other_payment?.total_with_tax.string ?? null,
            },
            handling: {
              name: cart_handling?.name ?? "",
              total_with_tax: cart_handling?.total_with_tax.string ?? null,
            },
            registration: {
              name: cart_registration?.name ?? "",
              total_with_tax: cart_registration?.total_with_tax.string ?? null,
            },
            setup_fee: {
              total_with_tax: {
                value: setup_fee?.total_with_tax.value ?? null,
                string: setup_fee?.total_with_tax.string ?? null,
              },
            },
            totalAmount: centsToAmount(
              total_with_tax.value + (setup_fee?.total_with_tax.value || 0)
            ),
          };
          const [widgetLoaded] = await Promise.all([
            loadWidget(merchant, assetKey, products),
            ...cartItems.map(({ image_urls }) => {
              const [url] = image_urls;
              return url ? prefetchImage(url) : Promise.resolve();
            }),
          ]);

          setCartData({
            status,
            cartItems,
            cartServices,
            cartSummary,
            checkoutRedirect,
            products,
            installmentsCount: installments_count,
            widgetLoaded,
            merchant_info,
            orderId: id,
            totalWithTax: centsToAmount(total_with_tax.value),
            withCatalogueImages: hasCatalogImages,
          });
        }
      };
      fetchCartItems();
    }
  });

  useEffect(() => {
    if (cartData) {
      setRenderData({
        ...cartData,
        creditAgreements: computeCreditAgreements(
          cartData.totalWithTax,
          true,
          cartData.cartSummary.setup_fee
        ),
      });
    }
  }, [cartData]);

  useEffect(() => {
    if (cartData?.orderId) {
      const { merchant_info, orderId } = cartData;
      trackUserEvent("View cart", {
        orderId,
        merchantReference: merchant_info.merchant,
        storeReference: merchant_info.merchantStore,
      });
    }
  }, [cartData?.orderId]);

  return (
    <ShopperCartContext.Provider value={renderData}>
      {children}
    </ShopperCartContext.Provider>
  );
};

ShopperCartProvider.propTypes = {
  children: PropTypes.node,
  handleChangeLocale: PropTypes.func,
};

ShopperCartProvider.defaultProps = {
  children: null,
  handleChangeLocale: () => null,
};

const useShopperCartContext = () => useContext(ShopperCartContext);

export default useShopperCartContext;
