import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import NoPictureIcon from "./CustomIcons/no-photography-24-px";

const size = ({ $small, $big }) => {
  if ($small) {
    return 48;
  }
  if ($big) {
    return 88;
  }
  return 72;
};

const svgTransform = ({ $small, $big }) => {
  if (!$big && !$small) {
    return "";
  }
  return `transform: scale(${$small ? 0.66 : 1.17});`;
};

const Wrap = styled.div`
  width: ${size}px;
  & > div {
    margin-top: 0;
    height: ${size}px;
    width: 100%;
    border-radius: 8px;
    background-image: ${({ $imgSrc }) =>
      $imgSrc ? `url("${$imgSrc}")` : "none"};
    background-color: ${({ theme }) => theme.palette.white};
    ${({ theme }) => theme.shadows.center}
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    & svg {
      ${svgTransform}
      & path:last-child {
        fill: ${({ theme }) => theme.palette.grey.secondary};
      }
    }
  }
`;

const CartItemImage = ({ image, small, big }) => (
  <Wrap $imgSrc={image} $small={small} $big={big}>
    <div>{image ? null : <NoPictureIcon />}</div>
  </Wrap>
);

CartItemImage.propTypes = {
  image: PropTypes.string,
  small: PropTypes.bool,
  big: PropTypes.bool,
};

CartItemImage.defaultProps = {
  image: null,
  small: false,
  big: false,
};

export default CartItemImage;
