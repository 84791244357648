import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <path
                d="M0 0H36V36H0z"
                transform="translate(-34 -412) translate(0 370) translate(16 24) translate(18 18)"
              />
              <path
                fillRule="nonzero"
                d="M16.224 12.238l-5.207-5.235 2.287-2.511h9.03l2.755 3.026h4.77c1.656 0 3.01 1.361 3.01 3.025v18.154c0 .076-.014.151-.03.242l-7.675-7.715c.12-.53.18-1.06.18-1.604 0-4.175-3.37-7.564-7.525-7.564-.541 0-1.068.06-1.595.182zm14.373 22.98l-3.492-3.495H5.78c-1.655 0-3.01-1.362-3.01-3.026V10.543c0-.892.407-1.694 1.024-2.254L.783 5.264 2.92 3.13l29.8 29.954-2.123 2.133zm-9.03-9.062l-2.258-2.27c-.481.152-.963.273-1.49.273-2.498 0-4.515-2.028-4.515-4.539 0-.53.12-1.013.286-1.482l-2.257-2.27c-.662 1.09-1.039 2.376-1.039 3.752 0 4.176 3.372 7.564 7.525 7.564 1.37 0 2.65-.378 3.748-1.028z"
                transform="translate(-34 -412) translate(0 370) translate(16 24) translate(18 18)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
