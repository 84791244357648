import React, { useState, lazy, Suspense } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { Spin } from "antd";
import useShopperCartContext from "../providers/ShopperCart";
import locales from "../locales";
import Disclaimer from "../ui/Disclaimer";

import {
  CartItem,
  CreditAgreementSimulator,
  PageLayout,
  Text,
  Button,
  TotalCartDiscount,
} from "../ui";

const AVAILABLE_PRODUCTS = ["pp3", "pp6", "pp9", "sp1", "fp1"];

const Wrap = styled.div`
  width: 100%;
  margin-top: -32px;
  .Cart_Summary {
    margin: 2rem 0 0;
    padding: 0 5px;
  }
  .PaymentOptions {
    border-top: 4px solid ${({ theme }) => theme.palette.grey.shade};
  }
  .PaymentOptions_Header {
    padding: 24px 0;
  }
  .PaymentOptions_title {
    margin-bottom: 8px !important;
  }
  .CreditAgreement {
    margin-bottom: 24px;
  }
  .content {
    padding: 16px 0;
  }
`;

const LoadingContent = styled.div`
  .ant-spin {
    margin-top: 46px;
    .ant-spin-dot {
      width: 39px;
      height: 39px;
    }
    .ant-spin-dot-item {
      width: 16px;
      height: 16px;
      background-color: ${({ theme }) => theme.palette.accent};
    }
  }
`;

const StatusCard = lazy(() => import("../ui/StatusCard"));

export default () => {
  const intl = useIntl();
  const shopperCartContextRenderData = useShopperCartContext();
  const [loading, setLoading] = useState(false);

  if (!shopperCartContextRenderData) {
    return (
      <PageLayout.Content noSidemenu>
        <LoadingContent>
          <Spin size="large" />
        </LoadingContent>
      </PageLayout.Content>
    );
  }

  const {
    status,
    cartServices,
    cartItems,
    cartSummary,
    checkoutRedirect,
    products,
    widgetLoaded,
    creditAgreements,
    installmentsCount,
    withCatalogueImages,
  } = shopperCartContextRenderData;

  const handleSubmit = (product) => {
    setLoading(true);
    checkoutRedirect(product);
  };

  const cartServicesBoxes = cartServices.map((service) => {
    const { id, quantity } = service;
    const shopper = {
      quantity,
      text: intl.formatMessage(locales.shopper_quantity_suffix, { quantity }),
    };
    return (
      <div key={id}>
        <CartItem
          withImage={withCatalogueImages}
          service
          item={service}
          shopper={shopper}
        />
      </div>
    );
  });
  const cartItemsBoxes = cartItems.map((item) => {
    const { id, quantity } = item;
    const shopper = {
      quantity,
      text: intl.formatMessage(locales.shopper_quantity_suffix, { quantity }),
    };
    return (
      <div key={id}>
        <CartItem
          withImage={withCatalogueImages}
          item={item}
          shopper={shopper}
        />
      </div>
    );
  });

  const creditBoxes = AVAILABLE_PRODUCTS.map((product) => {
    if (!products.includes(product)) {
      return null;
    }
    if (!creditAgreements?.[product]) {
      return null;
    }

    return (
      <div key={product} className="CreditAgreement">
        <CreditAgreementSimulator
          creditAgreements={{ [product]: creditAgreements?.[product] }}
          intl={intl}
          locales={locales}
          filteredInstallmentsCount={installmentsCount}
        />
        <div className="content">
          <Button
            data-testid={`${product}-button`}
            disabled={loading}
            onClick={() => handleSubmit(product)}
          >
            {products.length === 1
              ? intl.formatMessage(locales.shopper_pp_button)
              : intl.formatMessage(locales.shopper_pp_button_many)}
          </Button>
        </div>
      </div>
    );
  });

  const Widget = () => {
    return widgetLoaded ? (
      <div className="PaymentOptions">
        <div className="PaymentOptions_Header">
          <Text className="PaymentOptions_title" heading2>
            {intl.formatMessage(locales.shopper_payment_options)}
          </Text>
          <Text className="PaymentOptions_subtitle" body2 muted>
            {intl.formatMessage(locales.shopper_payment_options_subtitle)}
          </Text>
        </div>
        {creditBoxes}
      </div>
    ) : (
      <Suspense fallback={<Spin />}>
        <StatusCard
          status="error"
          title={intl.formatMessage(locales.widget_error_card_title)}
          description={intl.formatMessage(
            locales.widget_error_card_description
          )}
          button={{
            label: intl.formatMessage(locales.widget_error_card_button),
            link: () => window.location.reload(),
            style: "button",
          }}
        />
      </Suspense>
    );
  };

  const CartStatus = () => {
    switch (status) {
      case "error":
        return (
          <Suspense fallback={<Spin />}>
            <StatusCard
              status={status}
              title={intl.formatMessage(
                locales.shopper_cart_status_error_title
              )}
              description={intl.formatMessage(
                locales.shopper_cart_status_error_description
              )}
            />
          </Suspense>
        );
      case "confirmed":
        return (
          <Suspense fallback={<Spin />}>
            <StatusCard
              status="confirmed"
              title={intl.formatMessage(
                locales.shopper_cart_status_confirmed_title
              )}
              description={intl.formatMessage(
                locales.shopper_cart_status_confirmed_description
              )}
              button={{
                label: intl.formatMessage(
                  locales.shopper_cart_status_confirmed_button
                ),
                link: () =>
                  window.open(intl.formatMessage(locales.link_shopper_app)),
              }}
            />
          </Suspense>
        );
      case "cancelled":
      case "reimbursed":
        return (
          <Suspense fallback={<Spin />}>
            <StatusCard
              status={status}
              title={intl.formatMessage(
                locales.shopper_cart_status_cancelled_title
              )}
              description={intl.formatMessage(
                locales.shopper_cart_status_cancelled_description
              )}
              button={{
                label: intl.formatMessage(
                  locales.shopper_cart_status_cancelled_button
                ),
                link: () => window.open(intl.formatMessage(locales.link_faqs)),
              }}
            />
          </Suspense>
        );
      case "on_hold":
        return (
          <Suspense fallback={<Spin />}>
            <StatusCard
              status="on_hold"
              title={intl.formatMessage(
                locales.shopper_cart_status_on_hold_title
              )}
              description={intl.formatMessage(
                locales.shopper_cart_status_on_hold_description
              )}
              button={{
                label: intl.formatMessage(
                  locales.shopper_cart_status_on_hold_button
                ),
                link: () =>
                  window.open(intl.formatMessage(locales.link_shopper_app)),
              }}
            />
          </Suspense>
        );
      default:
        return <Widget />;
    }
  };

  return (
    <PageLayout.Content noSidemenu>
      <Wrap>
        <div>{cartServicesBoxes}</div>
        <div>{cartItemsBoxes}</div>
        <div className="Cart_Summary">
          <TotalCartDiscount {...cartSummary} intl={intl} locales={locales} />
        </div>
        <CartStatus />
        <Disclaimer intl={intl} locales={locales} />
      </Wrap>
    </PageLayout.Content>
  );
};
