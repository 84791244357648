import React from "react";
import { Typography } from "antd";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import Highlighter from "react-highlight-words";

const fontSize = ({
  visual1,
  visual2,
  visual3,
  heading1,
  heading2,
  heading4,
  heading5,
  cta_s,
  body2,
  body3,
  caption1,
  caption2,
}) => {
  if (visual1) {
    return 2.5;
  }
  if (heading1 || visual2) {
    return 1.5;
  }
  if (heading2) {
    return 1.188;
  }
  if (visual3) {
    return 1.062;
  }
  if (heading4 || heading5 || cta_s || body2) {
    return 0.875;
  }
  if (caption1) {
    return 0.812;
  }
  if (body3) {
    return 0.75;
  }
  if (caption2) {
    return 0.688;
  }
  return 1;
};

const fontWeight = ({
  heading1,
  heading2,
  heading3,
  heading4,
  heading5,
  cta_l,
  cta_s,
}) => {
  if (heading1 || heading2 || heading3 || heading4) {
    return "bold";
  }
  if (heading5 || cta_l || cta_s) {
    return 600;
  }
  return "normal";
};

const color = ({
  theme,
  secondary,
  success,
  warning,
  error,
  accent,
  white,
  muted,
}) => {
  const { palette } = theme;
  if (muted) {
    return palette.grey.muted;
  }
  if (secondary) {
    return palette.grey.secondary;
  }
  if (success) {
    return palette.success;
  }
  if (warning) {
    return palette.warning;
  }
  if (error) {
    return palette.error;
  }
  if (accent) {
    return palette.accent;
  }
  if (white) {
    return palette.white;
  }
  return palette.grey.primary;
};

const lineHeight = ({
  visual1,
  visual2,
  visual3,
  heading1,
  heading4,
  heading5,
  cta_s,
  body2,
  body3,
  caption1,
  caption2,
}) => {
  if (visual1) {
    return "40px";
  }
  if (heading1 || visual2) {
    return "32px";
  }
  if (body2) {
    return "20px";
  }
  if (visual3) {
    return "24px";
  }
  if (heading4 || heading5 || cta_s || body3) {
    return "18px";
  }
  if (caption1) {
    return "16px";
  }
  if (caption2) {
    return "14px";
  }
  return "normal";
};

const textAlign = ({ center, right }) => {
  if (center) {
    return "center";
  }
  if (right) {
    return "right";
  }
  return "left";
};

export const textStyle = css`
  width: 100%;
  margin: 0 !important;
  font-size: ${fontSize}rem !important;
  font-weight: ${fontWeight} !important;
  color: ${color} !important;
  line-height: ${lineHeight} !important;
  text-align: ${textAlign} !important;
  ${({ capitalize }) =>
    capitalize ? "&::first-letter{text-transform: uppercase;}" : ""}
  ${({ uppercase }) => (uppercase ? "text-transform: uppercase;" : "")}
  ${({ linethrough }) => (linethrough ? "text-decoration: line-through;" : "")}
  ${({ link }) =>
    link
      ? `
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }}
  `
      : ""}
`;

const { Text: AntText, Paragraph, Title } = Typography;

const StyledText = styled(AntText)`
  ${textStyle}
`;
const StyledParagraph = styled(Paragraph)`
  ${textStyle}
  margin-bottom: 0 !important;
`;
const StyledTitle = styled(Title)`
  ${textStyle}
`;

const headingRange = [1, 2, 3, 4, 5];
const headingProps = headingRange.map((n) => `heading${n}`);

const Text = ({ ellipsis, children, highlight, className, ...props }) => {
  const textProps = Object.keys(props).reduce(
    (prev, prop) => (props[prop] ? { ...prev, [prop]: 1 } : prev),
    { className }
  );

  const child = highlight ? (
    <Highlighter
      highlightClassName="highlight"
      searchWords={[highlight]}
      autoEscape
      textToHighlight={children}
    />
  ) : (
    children
  );

  const ellispisInt = parseInt(ellipsis, 10);
  if (Number.isInteger(ellispisInt) && ellispisInt > 1) {
    return (
      <StyledParagraph {...textProps} ellipsis={{ rows: ellispisInt }}>
        {child}
      </StyledParagraph>
    );
  }
  if (headingProps.some((prop) => textProps[prop])) {
    return (
      <StyledTitle
        {...textProps}
        ellipsis={!!ellipsis}
        level={headingRange.find((n) => textProps[`heading${n}`])}
      >
        {child}
      </StyledTitle>
    );
  }
  return (
    <StyledText {...textProps} ellipsis={!!ellipsis}>
      {child}
    </StyledText>
  );
};

Text.propTypes = {
  ellipsis: PropTypes.any,
  highlight: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

Text.defaultProps = {
  ellipsis: null,
  highlight: null,
  className: "",
  children: null,
};

export default Text;
