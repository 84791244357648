import React from "react";
import { Button as AntButton } from "antd";
import styled from "styled-components";
import PropTypes from "prop-types";
import Text from "./Text";

const colors =
  (active) =>
  ({ theme, disabled, $outlined, danger }) => {
    const {
      grey,
      white,
      actionBg,
      interactive,
      error,
      errorLight,
      errorBg,
      errorDark,
    } = theme.palette;
    let border = danger ? error : grey.primary;
    let background = $outlined ? white : border;
    if (disabled) {
      border = danger ? errorBg : actionBg;
      if (!$outlined) {
        background = border;
      }
    } else if (active) {
      const notOutlinedDark = danger ? errorDark : interactive.primaryPressed;
      if ($outlined) {
        background = danger ? errorLight : interactive.primaryPressedOutline;
      } else {
        background = notOutlinedDark;
        border = notOutlinedDark;
      }
    }
    return `
      background: ${background} !important;
      border-color: ${border} !important;
    `;
  };

const size = ({ $large, $small }) => {
  let padding = 2.188;
  let height = 3;
  if ($large) {
    padding = 3.25;
    height = 3.5;
  }
  if ($small) {
    padding = 1.312;
    height = 2.125;
  }
  return `
    padding: 0 ${padding}rem;
    height: ${height}rem;
  `;
};

const StyledButton = styled(AntButton)`
  border-radius: 4px;
  border-width: 2px;
  ${({ disabled }) =>
    disabled ? "cursor: default !important; pointer-events: none;" : ""}
  box-shadow: none !important;
  transition: none;
  ${size}
  ${colors(false)}
  &:hover, &:active {
    ${colors(true)}
  }
  &::after,
  &::before {
    display: none !important;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    width: 100%;
  }
`;

const Button = ({
  onClick,
  children,
  htmlType,
  outlined,
  danger,
  large,
  small,
  disabled,
  className,
  ...props
}) => (
  <StyledButton
    onClick={onClick}
    disabled={disabled}
    htmlType={htmlType}
    $large={large}
    $small={small}
    $outlined={outlined}
    danger={danger}
    data-testid="button"
    className={className}
    {...props}
  >
    <Text
      center
      muted={disabled}
      cta_s={small}
      cta_l={!small}
      white={!outlined}
      error={danger && outlined}
    >
      {children}
    </Text>
  </StyledButton>
);

Button.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  htmlType: PropTypes.string,
  outlined: PropTypes.bool,
  danger: PropTypes.bool,
  large: PropTypes.bool,
  small: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

Button.defaultProps = {
  onClick: () => null,
  children: null,
  htmlType: "button",
  outlined: false,
  danger: false,
  large: false,
  small: false,
  disabled: false,
  className: "",
};

export default Button;
