/* eslint-disable react/no-danger */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Text from "./Text";
import { centsToAmount } from "../utils";
import CreditAgreementInstalment from "./CreditAgreementInstalment";

const CreditAgreement = styled.div`
  .CreditAgreement_Header {
    padding: 16px 0;
  }
  .CreditAgreement_Overmax {
    margin: 8px 0 0;
  }
`;

const CreditAgreementSimulator = ({
  creditAgreements,
  intl,
  locales,
  filteredInstallmentsCount,
}) => {
  if (!creditAgreements) {
    return null;
  }

  const productCodes = ["pp3", "sp1", "pp9", "pp6", "fp1"];

  const items = productCodes.map((product) => {
    const agreement = creditAgreements[product];
    if (!agreement) {
      return null;
    }
    let productInstallmentsCount;
    if (filteredInstallmentsCount) {
      productInstallmentsCount = filteredInstallmentsCount.find(
        (el) => el.product === product
      );
      if (!productInstallmentsCount) {
        return null;
      }
    }
    if (product === "sp1") {
      const params = {};
      let warningMessage = "";
      let disableProduct = false;
      const {
        cost_of_credit,
        max_amount,
        min_amount,
        total_with_tax,
        instalment_amount,
        instalment_count,
        grand_total,
      } = agreement[0];

      if (max_amount.value < total_with_tax.value) {
        disableProduct = true;
        warningMessage = "simulator_sp1_over_max";
        params.max = `${centsToAmount(max_amount.value)} €`;
      } else if (min_amount.value > total_with_tax.value) {
        disableProduct = true;
        warningMessage = "simulator_below_min";
        params.min = `${centsToAmount(min_amount.value)} €`;
      }

      return (
        <CreditAgreement key={product}>
          <div className="CreditAgreement_Header">
            <Text heading3 muted={disableProduct}>
              {intl.formatMessage(locales.simulator_sp1_heading)}
            </Text>
          </div>
          {warningMessage && (
            <Text secondary>
              {intl.formatMessage(locales[warningMessage], params)}
            </Text>
          )}
          <CreditAgreementInstalment
            key={product + instalment_count}
            instalment_total={centsToAmount(instalment_amount.value)}
            instalment_count={`${instalment_count} plazos`}
            currencyMonth={intl.formatMessage(locales.euro_month)}
            fees_total={`${intl.formatMessage(locales.simulator_pp_total)}: ${
              grand_total.string
            }`}
            cost_of_credit={{
              value: cost_of_credit.value,
              string:
                cost_of_credit.value === 0
                  ? intl.formatMessage(locales.simulator_sp3_free_instalment)
                  : `${intl.formatMessage(locales.cost)} ${centsToAmount(
                      cost_of_credit.value
                    )} €`,
            }}
          />
        </CreditAgreement>
      );
    }

    if (["pp3", "pp6", "pp9"].includes(product)) {
      let newAgreement = [...agreement];

      if (productInstallmentsCount) {
        newAgreement = agreement.filter((ppAgreement) =>
          productInstallmentsCount.values.includes(ppAgreement.instalment_count)
        );
      }
      if (newAgreement.length === 0) {
        return null;
      }
      if (newAgreement.every((ppAgreement) => !ppAgreement.available)) {
        return (
          <CreditAgreement key={product + newAgreement[0].instalment_count}>
            <div className="CreditAgreement_Header">
              <Text heading3>
                {intl.formatMessage(locales.simulator_pp_heading)}
              </Text>
            </div>
            <Text secondary>
              {intl.formatMessage(locales.simulator_below_min, {
                min: `${centsToAmount(newAgreement[0].min_amount.value)} €`,
              })}
            </Text>
          </CreditAgreement>
        );
      }
      const isFlexi = newAgreement.some((pp) => pp.cost_of_credit.value === 0);
      const isPPOverMax = newAgreement.some(
        (pp) => pp.total_with_tax.value > pp.max_amount.value
      );

      const ppItems = newAgreement
        .filter((ppAgreement) => ppAgreement.available)
        .reverse()
        .map((ppAgreement) => {
          const firstInstallment = () => {
            if (ppAgreement.setup_fee.total_with_tax.value || isPPOverMax) {
              return `${intl.formatMessage(
                locales.first_installment
              )}: ${centsToAmount(
                ppAgreement.down_payment_total.value +
                  ppAgreement.setup_fee.total_with_tax.value
              )} € (${intl.formatMessage(locales.installment).toLowerCase()}
              ${
                ppAgreement.setup_fee.total_with_tax.value
                  ? ` + ${intl.formatMessage(locales.setup_fee).toLowerCase()}`
                  : ""
              }
              ${
                isPPOverMax
                  ? ` + ${intl.formatMessage(locales.not_bankable)}`
                  : ""
              })`;
            }
            return "";
          };
          return (
            <CreditAgreementInstalment
              key={ppAgreement.product + ppAgreement.instalment_count}
              instalment_total={centsToAmount(
                ppAgreement.instalment_total.value
              )}
              instalment_count={intl.formatMessage(
                locales.simulator_instalments,
                { instalments: ppAgreement.instalment_count }
              )}
              currencyMonth={intl.formatMessage(locales.euro_month)}
              fees_total={`${intl.formatMessage(
                locales.simulator_pp_total
              )}: ${centsToAmount(ppAgreement.grand_total.value)} €`}
              firstInstallment={firstInstallment()}
              cost_of_credit={{
                value: ppAgreement.cost_of_credit.value,
                string:
                  ppAgreement.cost_of_credit.value === 0
                    ? intl.formatMessage(locales.simulator_sp3_free_instalment)
                    : `${centsToAmount(
                        ppAgreement.instalment_fee.value
                      )} ${intl.formatMessage(locales.euro_instalment)}`,
              }}
            />
          );
        });

      const [instalment_fee, max_amount] = ["instalment_fee", "max_amount"].map(
        (key) => centsToAmount(newAgreement[0][key].value)
      );

      return (
        <CreditAgreement key={product}>
          <div className="CreditAgreement_Header">
            <Text heading3>
              {isFlexi
                ? intl.formatMessage(locales.simulator_pp_heading)
                : intl.formatMessage(
                    locales[`simulator_${product}_intro`] ?? {
                      id: `simulator_${product}_intro`,
                    },
                    {
                      instalment_fee,
                      overMax: isPPOverMax
                        ? intl.formatMessage(locales.simulator_pp_over_max, {
                            max: max_amount,
                          })
                        : "",
                    }
                  )}
            </Text>
            {isPPOverMax && (
              <p className="CreditAgreement_Overmax">
                {intl.formatMessage(locales.simulator_pp_over_max, {
                  max: max_amount,
                })}
              </p>
            )}
          </div>
          {ppItems}
        </CreditAgreement>
      );
    }

    if (product === "fp1") {
      const totalFP1 = (
        <b>{`${centsToAmount(agreement[0].total_with_tax.value)} €`}</b>
      );
      return (
        <CreditAgreement key="fp1">
          <div className="CreditAgreement_Header">
            <Text heading3>
              {intl.formatMessage(locales.simulator_fp1_heading)}
            </Text>
          </div>
          <Text secondary>
            {intl.formatMessage(locales.simulator_fp1, {
              total: totalFP1,
            })}
          </Text>
        </CreditAgreement>
      );
    }

    return null;
  });

  if (items.every((item) => item === null)) {
    return null;
  }
  return items;
};

CreditAgreementSimulator.propTypes = {
  creditAgreements: PropTypes.object,
  intl: PropTypes.object,
  locales: PropTypes.object.isRequired,
  filteredInstallmentsCount: PropTypes.array,
};

CreditAgreementSimulator.defaultProps = {
  creditAgreements: {},
  intl: {
    formatMessage: (text) => text,
  },
  filteredInstallmentsCount: null,
};

export default CreditAgreementSimulator;
