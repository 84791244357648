import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { isLocalhost } from "./utils";
import App from "./App";

if (!isLocalhost) {
  Sentry.init({
    environment: window.env.SENTRY_ENVIRONMENT,
    dsn: window.env.SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing()],
    sampleRate: window.env.SENTRY_ERROR_RATE,
    tracesSampleRate: window.env.SENTRY_TRACES_RATE,
  });
}

ReactDOM.render(<App />, document.getElementById("root"));
