import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <path
                fill="#FFF"
                fillOpacity="0"
                d="M0 0H24V24H0z"
                transform="translate(-16 -502) translate(0 96) translate(0 390) translate(16 16)"
              />
              <g>
                <path
                  d="M0 0L24 0 24 24 0 24z"
                  transform="translate(-16 -502) translate(0 96) translate(0 390) translate(16 16)"
                />
                <path
                  d="M2.53 19.65l1.34.56v-9.03l-2.43 5.86c-.41 1.02.08 2.19 1.09 2.61zm19.5-3.7L17.07 3.98c-.31-.75-1.04-1.21-1.81-1.23-.26 0-.53.04-.79.15L7.1 5.95c-.75.31-1.21 1.03-1.23 1.8-.01.27.04.54.15.8l4.96 11.97c.31.76 1.05 1.22 1.83 1.23.26 0 .52-.05.77-.15l7.36-3.05c1.02-.42 1.51-1.59 1.09-2.6zM7.88 8.75c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-2 11c0 1.1.9 2 2 2h1.45l-3.45-8.34v6.34z"
                  transform="translate(-16 -502) translate(0 96) translate(0 390) translate(16 16)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
