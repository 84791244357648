import React, {
  createContext,
  useState,
  useContext,
  useRef,
  useEffect,
} from "react";
import { useLocalStorage, useSetState } from "react-use";
import PropTypes from "prop-types";

const AUTH_LOCAL_STORAGE_KEY = "INSTORE-SALES-FRONT-SESSION";
const TOKEN_EXPIRATION_DAYS = 7;

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [{ merchantReference, shopperCartCode }, setMerchantState] =
    useSetState(() => {
      const [, firstPathSegment, secondPathSegment] =
        window.location.pathname.split("/");
      if (firstPathSegment === window.env.RESERVED_SHOPPER_CART_PAGE) {
        return { merchantReference: null, shopperCartCode: secondPathSegment };
      }
      return {
        merchantReference: firstPathSegment || null,
        shopperCartCode: false,
      };
    });

  const [userInfo, setUserInfo] = useState(null);
  const apiMethods = useRef({ get: () => null, post: () => null });
  const [session, setSession, clearSession] = useLocalStorage(
    AUTH_LOCAL_STORAGE_KEY,
    null,
    {
      deserializer: (raw) => {
        if (raw) {
          const value = JSON.parse(raw);
          if (
            value.merchantReference !== merchantReference ||
            new Date(value.expires) <= new Date()
          ) {
            window.localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
            return null;
          }
          return value;
        }
        return null;
      },
    }
  );

  const updateSession = (data) => setSession({ ...session, ...data });
  const { token, expires, storeReference } = session || {};

  const updateToken = (newToken, newStoreReference = null) => {
    const data = {
      merchantReference,
      token: newToken,
    };
    if (newStoreReference) {
      data.storeReference = newStoreReference;
    }
    if (!expires) {
      data.expires = new Date(
        new Date().getTime() + TOKEN_EXPIRATION_DAYS * 24 * 60 * 60 * 1000
      ).toISOString();
    }
    updateSession(data);
  };

  const selectStoreReference = async (merchant_store_ref) => {
    const { post } = apiMethods.current;
    const response = await post("/api/v1/switch_store", { merchant_store_ref });
    if (response?.token) {
      updateToken(response.token, merchant_store_ref);
    }
  };

  useEffect(() => {
    if (token) {
      if (!userInfo) {
        const fetchUserInfo = async () => {
          const { get } = apiMethods.current;
          const response = await get("/api/v1/user_info");
          if (response) {
            if (response.merchant_stores.length === 1) {
              await selectStoreReference(response.merchant_stores[0].reference);
            }
            setUserInfo(response);
          }
        };
        fetchUserInfo();
      }
    } else if (userInfo !== null) {
      setUserInfo(null);
    }
  }, [token]);

  const authenticate = async (username, password, login_type) => {
    const { post } = apiMethods.current;
    const response = await post(
      "/api/v1/auth",
      { username, password, login_type, merchant_ref: merchantReference },
      false
    );
    if (!response) {
      return false;
    }
    if (response?.token) {
      updateToken(response.token);
      return true;
    }
    return false;
  };

  const logout = () => {
    clearSession();
    setUserInfo(null);
  };

  const getAuthToken = () => {
    if (!token || new Date(expires) <= new Date()) {
      logout();
      return false;
    }
    return token;
  };

  const refreshUserInfo = async () => {
    const { get } = apiMethods.current;
    const response = await get("/api/v1/user_info");
    setUserInfo(response);
  };

  const clearStoreReference = () =>
    setSession(
      Object.keys(session).reduce((prev, key) => {
        return key === "storeReference"
          ? prev
          : { ...prev, [key]: session[key] };
      }, {})
    );

  const value = {
    merchantReference,
    shopperCartCode,
    getAuthToken,
    selectStoreReference,
    clearStoreReference,
    storeReference,
    userInfo,
    refreshUserInfo,
    logout,
    isAuthenticated: Boolean(token && storeReference),
    pinAuthenticate: (pin) => authenticate(merchantReference, pin, "pin"),
    emailAuthenticate: (email, password) =>
      authenticate(email, password, "email"),
    setMerchantReference: (merchantRef) =>
      setMerchantState({ merchantReference: merchantRef }),
    requestAPIMethods: (methods) => {
      apiMethods.current = methods;
    },
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

AuthProvider.defaultProps = {
  children: null,
};

const useAuthContext = () => useContext(AuthContext);

export default useAuthContext;
