/* eslint-disable no-sequences */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import useAuthContext from "./Auth";
import useOrdersContext from "./Orders";
import useFeedbackContext from "./Feedback";
import { amountToCents, centsToAmount } from "../utils";
import locales from "../locales";

const WidgetSimulatorContext = createContext();

const loadWidget = (merchant, assetKey, products) =>
  new Promise((resolve, reject) => {
    const config = {
      merchant,
      assetKey,
      products,
      scriptUri: window.env.INTEGRATION_ASSETS_URL,
      decimalSeparator: ",",
      thousandSeparator: ".",
    };

    window.Sequra = { onLoad: () => null };

    ((i, s, o, g, r, a, m) => {
      i.SequraConfiguration = g;
      i.SequraOnLoad = [];
      i[r] = {};
      i[r][a] = (callback) => i.SequraOnLoad.push(callback);
      (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
      a.async = 1;
      a.src = g.scriptUri;
      m.parentNode.insertBefore(a, m);
      a.onerror = (error) => reject(error);
    })(window, document, "script", config, "Sequra", "onLoad");

    window.Sequra.onLoad(() => {
      resolve(true);
    });
  });

export const WidgetSimulatorProvider = ({ children }) => {
  const intl = useIntl();
  const { isAuthenticated, merchantReference } = useAuthContext();
  const { reportToSentry, displayError } = useFeedbackContext();
  const { schema } = useOrdersContext();
  const [{ hasError, hasLoaded, availableProducts }, setState] = useState({
    hasError: false,
    hasLoaded: false,
    availableProducts: [],
  });

  useEffect(() => {
    if (isAuthenticated && schema && !hasLoaded) {
      const autoLoad = async () => {
        const { asset_secret, product_codes } = schema || {};
        try {
          const loaded = await loadWidget(
            merchantReference,
            asset_secret,
            product_codes
          );
          setState({ hasLoaded: loaded, availableProducts: product_codes });
        } catch (error) {
          setState({ hasError: true });
          reportToSentry(error, {
            tags: { component: "seQura widget" },
            extra: { message: "Error loading SeQura Widget" },
          });
        }
      };
      autoLoad();
    }
  }, [isAuthenticated, schema, hasLoaded]);

  const computeCreditAgreements = useCallback(
    (amount, silentError = false, setup_fee = null) => {
      if (!window.Sequra || !hasLoaded) {
        !silentError &&
          displayError(intl.formatMessage(locales.simulator_not_available));
        return null;
      }
      try {
        const cents = amountToCents(amount);
        const result = window.Sequra.computeCreditAgreements({
          amount: cents.toString(),
        });
        if (availableProducts.includes("fp1")) {
          result.fp1 = [
            {
              total_with_tax: {
                value: cents,
                string: `${centsToAmount(cents)} €`,
              },
            },
          ];
        }

        if (setup_fee?.total_with_tax) {
          const credits = Object.keys(result).map((product) => {
            const values = result[product].map((agreements) => {
              return {
                ...agreements,
                setup_fee,
              };
            });
            return { [product]: values };
          });

          return credits.reduce((a, v, i) => {
            return { ...a, [Object.keys(credits[i])]: v[Object.keys(v)] };
          }, {});
        }

        return result;
      } catch (error) {
        reportToSentry(error, {
          tags: { component: "seQura widget" },
          extra: { message: "SeQura Widget error computing credit agreements" },
        });
        return null;
      }
    },
    [hasLoaded, availableProducts]
  );

  const value = {
    computeCreditAgreements,
    loadWidget: async (merchant, assetKey, products) => {
      try {
        const loaded = await loadWidget(merchant, assetKey, products);
        setState({ hasLoaded: loaded, availableProducts: products });
        return true;
      } catch (error) {
        reportToSentry(error, {
          tags: { component: "seQura widget" },
          extra: { message: "Error loading on demand SeQura Widget" },
        });
        return false;
      }
    },
    hasLoaded,
    hasError,
  };

  return (
    <WidgetSimulatorContext.Provider value={value}>
      {children}
    </WidgetSimulatorContext.Provider>
  );
};

WidgetSimulatorProvider.propTypes = {
  children: PropTypes.node,
};

WidgetSimulatorProvider.defaultProps = {
  children: null,
};

const useWidgetSimulatorContext = () => useContext(WidgetSimulatorContext);

export default useWidgetSimulatorContext;
