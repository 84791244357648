import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Credit = styled.div`
  border-collapse: collapse;
  padding: 16px 0;
  .Credit_Pricing {
    display: flex;
    justify-content: space-between;
  }
  .Credit_InstalmentAmount {
    font-size: 18px;
    margin-bottom: 8px;
    span {
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      margin-left: 8px;
    }
  }
  .Credit_Fees {
    color: ${({ theme }) => theme.palette.grey.muted};
  }
  .Credit_firstInstallment {
    margin-bottom: 6px;
  }
  .Credit_FeesTotal {
    display: inline-block;
    margin-right: 16px;
  }
`;

const Chip = styled.span`
  display: inline-block;
  border-radius: 16px;
  background-color: #b6eee5;
  font-size: 12px;
  color: #1c1c1c;
  padding: 0 8px;
`;

const CreditAgreementInstalment = ({
  instalment_total,
  instalment_count,
  currencyMonth,
  fees_total,
  firstInstallment,
  cost_of_credit,
}) => {
  return (
    <Credit>
      <div className="Credit_Pricing">
        <p className="Credit_InstalmentAmount">
          {instalment_total}
          <span>{currencyMonth}</span>
        </p>
        <b>{instalment_count}</b>
      </div>
      <div className="Credit_Fees">
        {firstInstallment && (
          <div className="Credit_firstInstallment">{firstInstallment}</div>
        )}
        <span className="Credit_FeesTotal">{fees_total}</span>
        <span className="Credit_FeesDetail">
          {cost_of_credit.value === 0 ? (
            <Chip>{cost_of_credit.string}</Chip>
          ) : (
            `(${cost_of_credit.string})`
          )}
        </span>
      </div>
    </Credit>
  );
};

CreditAgreementInstalment.propTypes = {
  instalment_total: PropTypes.string,
  instalment_count: PropTypes.string,
  currencyMonth: PropTypes.string,
  fees_total: PropTypes.string,
  firstInstallment: PropTypes.string,
  cost_of_credit: PropTypes.object,
};

CreditAgreementInstalment.defaultProps = {
  instalment_total: "",
  instalment_count: "",
  currencyMonth: "",
  fees_total: "",
  firstInstallment: "",
  cost_of_credit: {},
};

export default CreditAgreementInstalment;
